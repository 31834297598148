<template>
  <VNavbar style="height: 60px">
    <template #start>
      <VNavbarItem v-if="$user.hasPermission('ViewObjects')" route="/">Home</VNavbarItem>
      <VNavbarItem v-if="$user.hasPermission('ViewObjects')" :active="$route.path.startsWith('/portfolios')" route="/portfolios">Portfolios</VNavbarItem>
      <VNavbarItem v-if="$user.hasPermission('ViewObjects')" :active="$route.path.startsWith('/projects')" route="/projects">Projects</VNavbarItem>
      <VNavbarItem v-if="$user.hasPermission('ViewObjects')" :active="$route.path.startsWith('/data')" route="/data">Data</VNavbarItem>
      <VNavbarItem v-if="$user.hasPermission('ViewObjects')" :active="$route.path.startsWith('/models')" route="/models">Models</VNavbarItem>
    </template>

    <template #end>
      <VNavbarItem :active="$route.path.startsWith('/annotationprojects')" route="/annotationprojects">Annotation</VNavbarItem>
      <VNavbarItem v-if="$user.hasPermission('ViewObjects')" :active="$route.path.startsWith('/statistics')" route="/statistics">Statistics</VNavbarItem>
      <!-- <VNavbarItem :active="$route.path.startsWith('/network')" style="color: grey">Network</VNavbarItem> -->
      <VNavbarItem :active="$route.path.startsWith('/settings')" route="/settings">Settings</VNavbarItem>
      <VNavbarItem>
        <VPopover placement="top-end">
          <template #reference>
            <UserAvatar :size="32" style="font-size: 16px" use-hat :user-id="$user.id" />
          </template>

          <div class="user-info">
            <UserAvatar :size="40" style="font-size: 20px; font-weight: 500" :user-id="$user.id" />
            <div style="margin-left: 10px">
              <div class="bold" v-text="$user.full_name" />
              <div class="color-grey-40" v-text="$user.email" />
            </div>
          </div>

          <div class="divider" style="margin: 15px 0" />

          <VButton plain size="small" type="red" @click="logout()">Sign out</VButton>

          <template v-if="$user.is_superuser">
            <VButton plain size="small" @click="toAdmin">Admin</VButton>
          </template>
        </VPopover>
      </VNavbarItem>
    </template>
  </VNavbar>
</template>

<script setup lang="ts">
import { baseURL } from '@/bootstrap'

async function logout() {
  await api.post('logout')
  window.location.replace(window.location.origin)
}

function toAdmin() {
  window.location.replace(`${baseURL}/admin`)
}
</script>

<style lang="scss" scoped>
.user-info {
  display: flex;
  align-items: center;
  min-width: 250px;
}
</style>

import { StoreDefinition } from 'pinia'

import { Permission } from '@/types'

import { Organisation } from './organisation'
import { User } from './users'

export interface GlobalUser extends User {
  full_name: string
  websocket_token: string
  has_objects: boolean
  hasPermission: (permission: Permission) => boolean
  hasPermissions: (permissions: Permission[]) => boolean
}

/**
 * Check if `User` has the provided permission.
 */
function hasPermission(permission: Permission) {
  const permissions = [...$user.permissions, ...(stores.organisation?.organisation?.permissions ?? [])]
  return permissions.includes(permission)
}

/**
 * Check if `User` has any of the provided permissions.
 */
function hasPermissions(permissions: Permission[] = []) {
  return permissions.length ? !!permissions.find((permission) => $user.hasPermission(permission)) : true
}

export function createUserStore(id: string) {
  return defineStore(id, () => {
    const user = ref<GlobalUser>({
      date_of_creation: '',
      email: '',
      firenze_organisation_id: 0,
      firenze_organisation: {} as Organisation,
      first_name: '',
      full_name: '',
      function: '',
      id: 0,
      is_active: true,
      is_staff: false,
      is_superuser: false,
      last_name: '',
      last_updated: '',
      permissions: [],
      role: 'developer',
      websocket_token: '',
      has_objects: true,
      hasPermission,
      hasPermissions,
    })
    const authenticated = ref(false)

    function load(user: GlobalUser) {
      updateUser(user)
    }

    function updateUser(userUpdate: Partial<GlobalUser>) {
      Object.assign(user.value, userUpdate)
    }

    return { authenticated, load, user }
  })
}

export const useUserStore = createUserStore('user')

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useUserStore as unknown as StoreDefinition, import.meta.hot))
}

import { StoreDefinition } from 'pinia'

import { Relation, StateBook, StoreRelation } from '.'
import { AnnotationProject, AnnotationProjectRelation } from './annotationprojects'
import { debounceOption, StandardBook, useStore } from './composables'
import { Book } from './index.d'
import { OrganisationRelation } from './organisation'
import { UserRelation } from './users'

interface AnnotationDatumChildren {
  firenze_organisation: OrganisationRelation
  firenze_user: UserRelation
  firenze_annotation_project: AnnotationProjectRelation
}

export type AnnotationDatumRelation = Relation<AnnotationDatum, Appends, AnnotationDatumChildren>

export interface AnnotationDataQuery {
  pk: number
  archived: boolean
  languages__contains: string[]
  entry_name__icontains: string
  status__in: string[]
  technique__in: string[]
  firenze_user_ids__in: number[]
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface Appends {}

const defaultQuery = {
  default: {
    archived: false,
  },
}

export interface AnnotationDatum extends Book {
  LIBRARY: 'annotationdata'
  entry_name: string
  firenze_annotation_project_id: number
  firenze_annotation_project: AnnotationProject
  data: Record<string, string | number> & { key: string } // Single row data of a dataset. Use `train_column` etc to retrieve values.
}

export function createAnnotationDataStore<T extends StoreRelation<T, AnnotationDatumRelation, AnnotationDatum, AnnotationDatumChildren, Appends>>(id: string, initialRelations: T) {
  const library = 'annotationdata'
  type StoreAnnotationDatum = StateBook<T['fields'], T['appends'], T['hidden_fields'], T['children'], AnnotationDatum, Appends, AnnotationDatumChildren, typeof library>

  return defineStore(
    id,
    () => {
      const annotationdata = ref<StoreAnnotationDatum[]>([])
      const updateAvailable = ref(false)

      const relations = ref<AnnotationDatumRelation>({ ...initialRelations, query: defaultQuery })
      const { fetch, listenOnSocket, meta, relations: _relations, remove, resetQuery, sendRequest, update, updateQuery, updateSort } = useStore(library, relations, annotationdata as Ref<StandardBook[]>, updateAvailable)

      return { annotationdata, fetch, listenOnSocket, meta, relations, remove, resetQuery, sendRequest, update, updateAvailable, updateQuery, updateSort }
    },
    { ...debounceOption() }
  )
}

export const useAnnotationDataStore = createAnnotationDataStore('annotationdata', {
  // fields: ['id', 'text', 'firenze_user'], // TODO: id or children.
  fields: ['id', 'data'],
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useAnnotationDataStore as unknown as StoreDefinition, import.meta.hot))
}

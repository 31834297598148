import { DialogFeatureRequestForm } from '@/components/dialogs/DialogFeatureRequest.vue'
import { DialogMalfunctionReportForm } from '@/components/dialogs/DialogMalfunctionReport.vue'

export interface FormEmits {
  (event: 'update:visible', value: boolean): void
}

const categories: Record<string, string[]> = {
  Annotation: ['annotationprojects', 'annotationprojects.id'],
  Data: ['data', 'data.id', 'data.id.version'],
  Experiments: ['project.id.experiment'],
  Home: ['home'],
  Miscellaneous: ['miscellaneous'],
  Models: ['models', 'models.id'],
  Portfolios: ['portfolios', 'portfolios.id'],
  Projects: ['projects', 'projects.id'],
  Settings: ['settings'],
  Statistics: ['statistics'],
}

function getCategory() {
  for (const match of router.currentRoute.value.matched.reverse()) {
    const name = match.name?.toString()
    if (name) {
      const category = Object.entries(categories).find((entry) => entry[1].includes(name))
      if (category) return category[0]
    }
  }
  return ''
}

export function useSupportReport<TDefaultForm extends DialogMalfunctionReportForm | DialogFeatureRequestForm>(
  defaultForm: () => TDefaultForm,
  rulesKey: 'malfunctionreports' | 'featurerequests',
  props: { visible: boolean },
  emit: FormEmits
) {
  const defaultFormWithCategory = (): TDefaultForm => ({ ...defaultForm(), category: getCategory() })
  const { form, rules, setDefaultForm } = useForm(defaultFormWithCategory, rulesKey, emit)

  const categoryChangedByUser = ref(false)
  const loading = ref(false)
  const options = [
    { label: 'Yes', value: true },
    { label: 'No', value: false },
  ]

  onMounted(() => {
    bus.on('report-submitted-stop-loading', loadFalse)
  })

  onBeforeUnmount(() => {
    bus.off('report-submitted-stop-loading', loadFalse)
  })

  watch(
    () => props.visible,
    (newValue) => {
      if (newValue && !categoryChangedByUser.value) {
        form.category = getCategory()
      }
    }
  )

  async function hide() {
    emit('update:visible', false)
    await delay(300)
  }

  function loadFalse() {
    loading.value = false
  }

  function updateAttachment(files: File[]) {
    if (files.some((file) => file.size > 2000000)) {
      $message.error('File is too large. The maximum size is 2MB')
      return
    }

    form.attachment = files
  }

  return { categories, categoryChangedByUser, form, hide, loading, options, rules, setDefaultForm, updateAttachment }
}

<template>
  <picture key="auth-background">
    <source srcset="/images/background.webp" type="image/webp" />
    <source srcset="/images/background.jpg" type="image/jpeg" />
    <img alt="Background" class="background" src="/images/background.jpg" />
  </picture>
</template>

<style lang="scss" scoped>
.background {
  min-width: 100%;
  height: 100%;
  position: absolute;
  background-color: var(--color-primary);
  object-fit: cover;
}
</style>

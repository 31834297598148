import type { VTagType } from '@vartion/ui'
import { StoreDefinition } from 'pinia'

import { Relation, StateBook, StoreRelation } from '.'
import { AnnotationProject, AnnotationProjectRelation } from './annotationprojects'
import { debounceOption, StandardBook, useStore } from './composables'
import { Book } from './index.d'
import { OrganisationRelation } from './organisation'
import { UserRelation } from './users'

interface AnnotationLabelChildren {
  firenze_organisation: OrganisationRelation
  firenze_user: UserRelation
  firenze_annotation_project: AnnotationProjectRelation
}

export type AnnotationLabelRelation = Relation<AnnotationLabel, Appends, AnnotationLabelChildren>

export interface AnnotationLabelsQuery {
  pk: number
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface Appends {}

const defaultQuery = {
  default: {},
}

export interface AnnotationLabel extends Book {
  LIBRARY: 'annotationlabels'
  colour: VTagType
  entry_name: string
  firenze_annotation_project_id: number
  firenze_annotation_project: AnnotationProject
  guideline: string
  index: number
}

export function createAnnotationLabelsStore<T extends StoreRelation<T, AnnotationLabelRelation, AnnotationLabel, AnnotationLabelChildren, Appends>>(id: string, initialRelations: T) {
  const library = 'annotationlabels'
  type StoreAnnotationLabel = StateBook<T['fields'], T['appends'], T['hidden_fields'], T['children'], AnnotationLabel, Appends, AnnotationLabelChildren, typeof library>

  return defineStore(
    id,
    () => {
      const annotationlabels = ref<StoreAnnotationLabel[]>([])
      const updateAvailable = ref(false)

      const relations = ref<AnnotationLabelRelation>({ ...initialRelations, query: defaultQuery })
      const { fetch, listenOnSocket, meta, relations: _relations, remove, resetQuery, sendRequest, update, updateQuery, updateSort } = useStore(library, relations, annotationlabels as Ref<StandardBook[]>, updateAvailable)

      return { annotationlabels, fetch, listenOnSocket, meta, relations, remove, resetQuery, sendRequest, update, updateAvailable, updateQuery, updateSort }
    },
    { ...debounceOption() }
  )
}

export const useAnnotationLabelsStore = createAnnotationLabelsStore('annotationlabels', {})
export type GlobalAnnotationLabel = ReturnType<typeof useAnnotationLabelsStore>['annotationlabels'][number]

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useAnnotationLabelsStore as unknown as StoreDefinition, import.meta.hot))
}

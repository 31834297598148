const techniques = {
  'tabular-classification': {
    description: 'Tabular classification is the task of assigning a set of predefined labels to a row of data.',
    icon: 'Table',
    technique: 'tabular-classification',
    title: 'Tabular classification',
    extensions: ['csv', 'tsv', 'jsonl', 'xlsx'],
    default_parsed_format: 'tabular-classification',
    configuration: {
      enable_annotation_project_labels_export: true,
      enable_create_annotation_project: true,
      enable_create_portfolio: true,
      enable_create_project: true,
      enable_multiple_experiments: true,
      enable_single_experiments: true,
      enable_teacher_models: false,
      enable_clean_data: false,
      enable_data_augmentation: false,
      enable_auto_ml: true,
    },
    subtypes: {
      'tabular-classification': {
        description: 'Each row is assigned a single label.',
        title: 'Tabular classification',
      },
    },
    examples: [
      {
        filename: 'tac_titanic.csv',
        help: 'Dataset which can be used to train a model to predict survival rates of Titanic passengers. Suggested prediction column: "Survived". Suggested train columns: All columns except "PassengerId", "Sex", "Age", "key".',
        mime: 'text/csv',
        text: 'Titanic tabular CSV',
        url: 'https://firenze-app.ams3.digitaloceanspaces.com/assets/titanic.csv',
      },
      {
        filename: 'tac_wine.csv',
        help: 'Dataset which can be used to train a model to predict the type of wine. Suggested prediction column: "class". Suggested train columns: All columns except "key"',
        mime: 'text/csv',
        text: 'Wine tabular CSV',
        url: 'https://firenze-app.ams3.digitaloceanspaces.com/assets/wine.csv',
      },
      {
        filename: 'tac_medical_samples.csv',
        help: 'Dataset which can be used to train a model to predict to which medical speciality a transcript belongs to. Suggested prediction column: "medical_specialty". Suggested train columns: All columns except "Unnamed: 0", "key"',
        mime: 'text/csv',
        text: 'Medical Sample Transcript Speciality CSV',
        url: 'https://firenze-app.ams3.digitaloceanspaces.com/assets/tac_medical_samples.csv',
      },
    ],
  },
  'tabular-regression': {
    description: 'Tabular regression is the task of assigning a value to a row of data.',
    icon: 'Table',
    technique: 'tabular-regression',
    title: 'Tabular regression',
    extensions: ['csv', 'tsv', 'jsonl', 'xlsx'],
    default_parsed_format: 'tabular-regression',
    configuration: {
      enable_annotation_project_labels_export: false,
      enable_create_annotation_project: true,
      enable_create_portfolio: true,
      enable_create_project: true,
      enable_multiple_experiments: true,
      enable_single_experiments: true,
      enable_teacher_models: false,
      enable_clean_data: false,
      enable_data_augmentation: false,
      enable_auto_ml: true,
    },
    subtypes: {
      'tabular-regression': {
        description: 'Each row is assigned a single value.',
        title: 'Tabular regression',
      },
    },
    examples: [
      {
        filename: 'tar_california_housing.csv',
        help: 'Dataset which can be used to train a model to predict the value estimation of a house. Suggested prediction column: "MedHouseVal". Suggested train columns: All columns',
        mime: 'text/csv',
        text: 'California Housing Prices CSV',
        url: 'https://firenze-app.ams3.digitaloceanspaces.com/assets/tar_california_housing.csv',
      },
    ],
  },
  'text-classification': {
    description: 'Text classification is the task of assigning a set of predefined categories to open-ended text.',
    icon: 'Article',
    technique: 'text-classification',
    title: 'Text classification',
    extensions: ['csv', 'tsv', 'jsonl', 'xlsx'],
    default_parsed_format: 'text-class',
    configuration: {
      enable_annotation_project_labels_export: true,
      enable_create_annotation_project: true,
      enable_create_portfolio: true,
      enable_create_project: true,
      enable_multiple_experiments: true,
      enable_single_experiments: true,
      enable_teacher_models: true,
      enable_clean_data: true,
      enable_data_augmentation: true,
      enable_auto_ml: true,
    },
    subtypes: {
      'text-class': {
        description: 'Each text is assigned a single label.',
        title: 'Multi class single label',
      },
      'text-classes': {
        description: 'Each text is assigned one or multiple labels.',
        title: 'Multi class multi label',
      },
    },
    examples: [
      {
        filename: 'tc_20newsgroups_multi_label.csv',
        help: 'Dataset which can be used to train a model to predict multiple categories of a news article. Suggested prediction column: "label". Suggested train column: "text"',
        mime: 'text/csv',
        text: '20 Newsgroups Multi Label Category CSV',
        url: 'https://firenze-app.ams3.digitaloceanspaces.com/assets/tc_20newsgroups_multi_label.csv',
      },
      {
        filename: 'tc_20newsgroups_single_label.csv',
        help: 'Dataset which can be used to train a model to predict a single category of a news article. Suggested prediction column: "label". Suggested train column: "text"',
        mime: 'text/csv',
        text: '20 Newsgroups Single Label Category CSV',
        url: 'https://firenze-app.ams3.digitaloceanspaces.com/assets/tc_20newsgroups_single_label.csv',
      },
      {
        filename: 'tc_spam_text_messages_single_label.csv',
        help: 'Dataset which can be used to train a model to predict whether a text message is spam or not. Suggested prediction column: "Category". Suggested train column: "Message"',
        mime: 'text/csv',
        text: 'Text Message Spam Category CSV',
        url: 'https://firenze-app.ams3.digitaloceanspaces.com/assets/tc_spam_text_messages_single_label.csv',
      },
    ],
  },
  'deeprank-3d-cnn': {
    description: 'A deep learning framework for data mining 3D protein-protein interfaces',
    icon: 'Projects',
    technique: 'deeprank-3d-cnn',
    title: 'DeepRank 3D CNN',
    extensions: ['zip'],
    default_parsed_format: 'deeprank-3d-cnn',
    subtypes: {
      'deeprank-3d-cnn': {
        description: 'DeepRank 3D CNN',
        title: 'DeepRank 2D CNN',
      },
    },
    configuration: {
      enable_annotation_project_labels_export: false,
      enable_create_annotation_project: false,
      enable_create_portfolio: true,
      enable_create_project: true,
      enable_multiple_experiments: false,
      enable_single_experiments: true,
      enable_teacher_models: false,
      enable_clean_data: false,
      enable_data_augmentation: false,
      enable_auto_ml: false,
    },
  },
  'image-classification': {
    description: 'Image classification is the task of assigning a set of predefined categories to images.',
    icon: 'Photo',
    technique: 'image-classification',
    title: 'Image classification',
    extensions: ['zip', 'jsonl'],
    default_parsed_format: 'image-classification',
    configuration: {
      enable_annotation_project_labels_export: false,
      enable_create_annotation_project: true,
      enable_create_portfolio: true,
      enable_create_project: true,
      enable_multiple_experiments: true,
      enable_single_experiments: true,
      enable_teacher_models: false,
      enable_clean_data: false,
      enable_data_augmentation: false,
      enable_auto_ml: false,
    },
    subtypes: {
      'image-classification': {
        description: 'Each image is assigned a single label.',
        title: 'Multi class single label',
      },
    },
  },
  'time-series-classification': {
    description: 'Time series classification the task of assigning a class or label to a time series.',
    icon: 'Clock',
    technique: 'time-series-classification',
    title: 'Time series classification',
    extensions: ['csv', 'tsv', 'xlsx'],
    default_parsed_format: 'time-series-classification',
    configuration: {
      enable_annotation_project_labels_export: false,
      enable_create_annotation_project: false,
      enable_create_portfolio: true,
      enable_create_project: true,
      enable_multiple_experiments: true,
      enable_single_experiments: true,
      enable_teacher_models: false,
      enable_clean_data: false,
      enable_data_augmentation: false,
      enable_auto_ml: true,
    },
    subtypes: {
      'time-series-classification': {
        description: 'Each time series is assigned a class or label.',
        title: 'Time series classification',
      },
    },
    examples: [],
  },
  'time-series-regression': {
    description: 'Time series regression the task of assigning a value to a time series.',
    icon: 'Clock',
    technique: 'time-series-regression',
    title: 'Time series regression',
    extensions: ['csv', 'tsv', 'xlsx'],
    default_parsed_format: 'time-series-regression',
    configuration: {
      enable_annotation_project_labels_export: false,
      enable_create_annotation_project: false,
      enable_create_portfolio: true,
      enable_create_project: true,
      enable_multiple_experiments: true,
      enable_single_experiments: true,
      enable_teacher_models: false,
      enable_clean_data: false,
      enable_data_augmentation: false,
      enable_auto_ml: true,
    },
    subtypes: {
      'time-series-regression': {
        description: 'Each time series is assigned a value.',
        title: 'Time series regression',
      },
    },
    examples: [],
  },
  'time-series-forecasting': {
    description: 'Time series forecasting the task of determining new data points following the existing series.',
    icon: 'Clock',
    technique: 'time-series-forecasting',
    title: 'Time series forecasting',
    extensions: ['csv', 'tsv', 'xlsx'],
    default_parsed_format: 'time-series-forecasting',
    configuration: {
      enable_annotation_project_labels_export: false,
      enable_create_annotation_project: false,
      enable_create_portfolio: true,
      enable_create_project: true,
      enable_multiple_experiments: true,
      enable_single_experiments: true,
      enable_teacher_models: false,
      enable_clean_data: false,
      enable_data_augmentation: false,
      enable_auto_ml: true,
    },
    subtypes: {
      'time-series-forecasting': {
        description: 'New data points following the existing series can be predicted.',
        title: 'Time series forecasting',
      },
    },
    examples: [],
  },
  'text-captioning': {
    description: 'Text captioning is the task of assigning a text to another text. Training is not yet supported.',
    icon: 'Globe',
    extensions: ['csv', 'tsv', 'jsonl', 'xlsx'],
    configuration: {
      enable_annotation_project_labels_export: false,
      enable_create_annotation_project: true,
      enable_create_portfolio: true,
      enable_create_project: false,
      enable_multiple_experiments: false,
      enable_single_experiments: true,
      enable_teacher_models: false,
      enable_clean_data: false,
      enable_data_augmentation: false,
      enable_auto_ml: false,
    },
    technique: 'text-captioning',
    title: 'Text captioning',
    default_parsed_format: 'text-caption',
    subtypes: {
      'text-caption': {
        description: 'Each text can be captioned.',
        title: 'Text captioning',
      },
    },
    examples: [],
  },
  'named-entity-recognition': {
    configuration: {
      enable_annotation_project_labels_export: true,
      enable_auto_ml: false,
      enable_clean_data: false,
      enable_create_annotation_project: false,
      enable_create_portfolio: true,
      enable_create_project: false,
      enable_data_augmentation: false,
      enable_multiple_experiments: false,
      enable_single_experiments: true,
      enable_teacher_models: false,
    },
    default_parsed_format: 'named-entity-recognition',
    description: 'Named entity recognition (NER) is the task of assigning labels to parts of text. Training is not yet supported.',
    examples: [],
    extensions: ['csv', 'tsv', 'jsonl', 'xlsx', 'json'],
    icon: 'Globe',
    subtypes: {
      'named-entity-recognition': {
        description: 'NER',
        title: 'NER',
      },
    },
    technique: 'named-entity-recognition',
    title: 'Named entity recognition',
  },
  'relationship-extraction': {
    description: 'Relationship extraction is the task of assigning a set of predefined relations to open-ended text.',
    icon: 'UserFriends',
    technique: 'relationship-extraction',
    title: 'Relationship extraction',
    extensions: ['jsonl'],
    default_parsed_format: 'text-relation',
    configuration: {
      enable_annotation_project_labels_export: false,
      enable_create_annotation_project: true,
      enable_create_portfolio: true,
      enable_create_project: true,
      enable_multiple_experiments: false,
      enable_single_experiments: true,
      enable_teacher_models: false,
      enable_clean_data: false,
      enable_data_augmentation: false,
      enable_auto_ml: false,
    },
    subtypes: {
      'text-relation': {
        description: 'Each text is assigned a single relation.',
        title: 'Relationship extraction',
      },
    },
    examples: [],
  },
  'node-classification': {
    configuration: {
      enable_annotation_project_labels_export: false,
      enable_auto_ml: false,
      enable_clean_data: false,
      enable_create_annotation_project: false,
      enable_create_portfolio: true,
      enable_create_project: true,
      enable_data_augmentation: false,
      enable_multiple_experiments: true,
      enable_single_experiments: false,
      enable_teacher_models: false,
    },
    default_parsed_format: 'node-classification-single',
    description: 'Node classification (NC) is the task of assigning labels to nodes in a graph.',
    examples: [
      {
        filename: 'pubmed-data.zip',
        help: 'Dataset which can be used to train a model to predict the topic of research papers.',
        mime: 'application/zip',
        text: 'Pubmed ZIP',
        url: 'https://firenze-app.ams3.digitaloceanspaces.com/assets/nc_pubmed_data.zip',
      },
    ],
    extensions: ['zip'],
    icon: 'Boxes',
    subtypes: {
      'node-classification-single': {
        description: 'NC',
        title: 'NC',
      },
    },
    technique: 'node-classification',
    title: 'Node classification',
  },
} as const

export { techniques }

import { StoreDefinition } from 'pinia'

import { Relation, StateBook, StoreRelation } from '.'
import { debounceOption, StandardBook, useStore } from './composables'
import { DataVersion, DataVersionRelation } from './dataversions'
import { Book } from './index.d'
import { OrganisationRelation } from './organisation'
import { Portfolio, PortfolioRelation } from './portfolios'
import { ParsedFormat, PredictColumn, Technique } from './types'
import { UserRelation } from './users'

interface AnnotationProjectChildren {
  firenze_organisation: OrganisationRelation
  firenze_user: UserRelation
  firenze_annotated_from_version: DataVersionRelation
  firenze_portfolios: PortfolioRelation
}

export type AnnotationProjectRelation = Relation<AnnotationProject, Appends, AnnotationProjectChildren>

export interface AnnotationProjectsQuery {
  pk: number
  archived: boolean
  languages__contains: string[]
  entry_name__icontains: string
  status__in: string[]
  technique__in: string[]
  firenze_user_ids__in: number[]
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface Appends {}

const defaultQuery = {
  default: {
    archived: false,
  },
}

export interface AnnotationProject extends Book {
  LIBRARY: 'annotationprojects'
  annotation_project_status: string
  archived: boolean
  description: string
  documentation: string
  entry_name: string
  firenze_annotated_from_version_id: number
  firenze_annotated_from_version: DataVersion
  firenze_data_version_id: number
  firenze_portfolios_ids: number[]
  firenze_portfolios: Portfolio[]
  general_guideline: string
  languages: (keyof typeof stores.jsons.renames.languages)[]
  licenses: (keyof typeof stores.jsons.renames.licenses)[]
  lifecycle: keyof typeof stores.jsons.renames.lifecycles
  parsed_format: ParsedFormat
  randomise: boolean
  reports: object
  technique: Technique
  predict_column: PredictColumn
}

export function createAnnotationProjectsStore<T extends StoreRelation<T, AnnotationProjectRelation, AnnotationProject, AnnotationProjectChildren, Appends>>(id: string, initialRelations: T) {
  const library = 'annotationprojects'
  type StoreAnnotationProject = StateBook<T['fields'], T['appends'], T['hidden_fields'], T['children'], AnnotationProject, Appends, AnnotationProjectChildren, typeof library>

  return defineStore(
    id,
    () => {
      const dialogSingleOpen = ref(false)
      const annotationprojects = ref<StoreAnnotationProject[]>([])
      const updateAvailable = ref(false)

      const relations = ref<AnnotationProjectRelation>({ ...initialRelations, query: defaultQuery })
      const { fetch, listenOnSocket, meta, relations: _relations, remove, resetQuery, sendRequest, update, updateQuery, updateSort } = useStore(library, relations, annotationprojects as Ref<StandardBook[]>, updateAvailable)

      return { annotationprojects, dialogSingleOpen, fetch, listenOnSocket, meta, relations, remove, resetQuery, sendRequest, update, updateAvailable, updateQuery, updateSort }
    },
    { ...debounceOption() }
  )
}

export const useAnnotationProjectsStore = createAnnotationProjectsStore('annotationprojects', {
  fields: ['annotation_project_status', 'last_updated', 'entry_name', 'technique', 'parsed_format'],
  children: {
    firenze_user: {
      fields: ['first_name', 'last_name'],
      appends: ['full_name'],
    },
  },
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useAnnotationProjectsStore as unknown as StoreDefinition, import.meta.hot))
}

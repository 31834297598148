<template>
  <div class="auth">
    <AuthBackground />

    <div class="container">
      <main class="panel">
        <RouterView />
      </main>
    </div>
  </div>
</template>

<style lang="scss">
.auth {
  a,
  .link {
    color: var(--color-info);
  }

  // inputs
  form {
    input,
    textarea,
    select,
    button {
      font-size: 20px;
    }

    input:focus,
    button:focus {
      outline: none;
    }

    input {
      &.form-control {
        width: 100%;
        border: none;
        border-bottom: var(--border);
        padding: 6px 0;
        background: none;
      }

      &:-webkit-autofill,
      &:-webkit-autofill:hover,
      &:-webkit-autofill:focus {
        box-shadow: 0 0 0 30px #f5f6f7 inset;
      }

      &::placeholder {
        color: #000;
        font-weight: 400;
      }
    }
  }

  .logo {
    width: 370px;
  }

  .container {
    height: 100vh;
    position: relative;
  }

  .panel {
    top: 0;
    left: 0;
    height: 100%;
    position: absolute;
    background: #f5f6f7;
    padding: 0 80px;
    width: 600px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .panel-heading {
    font-size: 30px;
    font-weight: 400;
  }

  .panel-body {
    margin-top: 80px;
  }

  .form-group {
    margin-bottom: 60px;
  }

  .buttons-group {
    margin-top: -40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  @media (max-width: $xs) {
    .panel {
      max-width: 100vw;
      padding: 0 30px;
      background: rgba(255, 255, 255, 0.95);
    }
    .container {
      width: 100%;
    }
    .panel-heading {
      font-size: 24px;
    }
  }
}
</style>

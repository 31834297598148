import { StoreDefinition } from 'pinia'

import { Optional } from '@/types'

import { Relation, StateBook, StoreRelation } from '.'
import { AnnotationProject } from './annotationprojects'
import { debounceOption, StandardBook, useStore } from './composables'
import { Book } from './index.d'
import { Model, ModelRelation } from './models'
import { OrganisationRelation } from './organisation'
import { Project } from './projects'
import { Status } from './statuses'
import { UserRelation } from './users'

interface PortfolioChildren {
  firenze_final_model: ModelRelation
  firenze_organisation: OrganisationRelation
  firenze_user: UserRelation
}

export interface PortfoliosQuery {
  archived: boolean
  entry_name__icontains: string
  status__in: string[]
  technique__in: string[]
  firenze_user_ids__in: number[]
}

const defaultQuery = {
  default: {
    archived: false,
  },
}

interface PortfolioStatus {
  orchestration: Status
  training: Status
}

interface Appends {
  controls_resolved: string
  status: PortfolioStatus
}

export interface Portfolio extends Book {
  LIBRARY: 'portfolios'
  archived: boolean
  description: string
  documentation: string
  entry_name: string
  firenze_final_model_id: Optional<number>
  firenze_final_model: Optional<Model>
  firenzeannotationproject_set: AnnotationProject[]
  firenzeproject_set: Project[]
  portfolio_status: keyof typeof stores.jsons.renames.status
  technique: keyof typeof stores.jsons.renames.techniques
}

export type PortfolioRelation = Relation<Portfolio, Appends, PortfolioChildren>

export function createPortfoliosStore<T extends StoreRelation<T, PortfolioRelation, Portfolio, PortfolioChildren, Appends>>(id: string, initialRelations: T) {
  const library = 'portfolios'
  type StorePortfolio = StateBook<T['fields'], T['appends'], T['hidden_fields'], T['children'], Portfolio, Appends, PortfolioChildren, typeof library>

  return defineStore(
    id,
    () => {
      const portfolios = ref<StorePortfolio[]>([])
      const updateAvailable = ref(false)

      const relations = ref<PortfolioRelation>({ ...initialRelations, query: defaultQuery })
      const { fetch, listenOnSocket, meta, relations: _relations, remove, resetQuery, sendRequest, update, updateQuery, updateSort } = useStore(library, relations, portfolios as Ref<StandardBook[]>, updateAvailable)

      return { portfolios, fetch, listenOnSocket, meta, relations, remove, resetQuery, sendRequest, update, updateAvailable, updateQuery, updateSort }
    },
    { ...debounceOption() }
  )
}

export const usePortfoliosStore = createPortfoliosStore('portfolios', {
  appends: ['controls_resolved'],
  fields: ['entry_name', 'technique', 'portfolio_status', 'last_updated'],
  children: {
    firenze_final_model: {
      appends: ['controls_resolved'],
      fields: ['environment', 'archived'],
    },
    firenze_user: {
      fields: ['first_name', 'last_name'],
      appends: ['full_name'],
    },
  },
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(usePortfoliosStore as unknown as StoreDefinition, import.meta.hot))
}

import { StoreDefinition } from 'pinia'

import { Relation, StateBook, StoreRelation } from '.'
import { debounceOption, StandardBook, useStore } from './composables'
import { DataVersion, DataVersionRelation } from './dataversions'
import { ExperimentGroup, ExperimentGroupRelation } from './experimentgroups'
import { Experiment, ExperimentRelation } from './experiments'
import { Book } from './index.d'
import { Job, JobRelation } from './jobs'
import { Model, ModelRelation } from './models'
import { OrganisationRelation } from './organisation'
import { Project, ProjectRelation } from './projects'
import { UserRelation } from './users'

interface StatusChildren {
  firenze_organisation: OrganisationRelation
  firenze_user: UserRelation
  firenze_job: JobRelation
  firenze_experiment: ExperimentRelation
  firenze_experiment_group: ExperimentGroupRelation
  firenze_data_version: DataVersionRelation
  firenze_model: ModelRelation
  firenze_project: ProjectRelation
}

export type StatusRelation = Relation<Status, Appends, StatusChildren>

export interface StatusesQuery {
  pk: number
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface Appends {}

const defaultQuery = {
  default: {},
}

export interface Status extends Book {
  LIBRARY: 'statuses'
  action: string
  linked_library: 'dataversions' | 'experiments' | 'experimentgroups' | 'models' | 'projects'
  max_step: number
  message: string
  status: 'to_start' | 'starting' | 'running' | 'completed' | 'error' | 'retry'
  step: number
  firenze_data_version_id: number
  firenze_data_version: DataVersion
  firenze_experiment_id: number
  firenze_experiment: Experiment
  firenze_experiment_group_id: number
  firenze_experiment_group: ExperimentGroup
  firenze_model_id: number
  firenze_model: Model
  firenze_job: Job
  firenze_job_id: number
  firenze_project_id: number
  firenze_project: Project
}

export function createStatusesStore<T extends StoreRelation<T, StatusRelation, Status, StatusChildren, Appends>>(id: string, initialRelations: T) {
  const library = 'statuses'
  type StoreStatus = StateBook<T['fields'], T['appends'], T['hidden_fields'], T['children'], Status, Appends, StatusChildren, typeof library>

  return defineStore(
    id,
    () => {
      const statuses = ref<StoreStatus[]>([])
      const updateAvailable = ref(false)

      const relations = ref<StatusRelation>({ ...initialRelations, query: defaultQuery })
      const { fetch, listenOnSocket, meta, relations: _relations, remove, resetQuery, sendRequest, update, updateQuery, updateSort } = useStore(library, relations, statuses as Ref<StandardBook[]>, updateAvailable)

      return { statuses, fetch, listenOnSocket, meta, relations, remove, resetQuery, sendRequest, update, updateAvailable, updateQuery, updateSort }
    },
    { ...debounceOption() }
  )
}

export const useStatusesStore = createStatusesStore('statuses', {})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useStatusesStore as unknown as StoreDefinition, import.meta.hot))
}

import { VFormRule } from '@vartion/ui'
import { Ref } from 'vue'

import { DialogAnnotationTabularRegressionDefaultForm } from '@/components/dialogs/annotation/DialogAnnotationTabularRegression.vue'
import { DialogAnnotationTextCaptionDefaultForm } from '@/components/dialogs/annotation/DialogAnnotationTextCaption.vue'
import { DialogAnnotationProjectAddForm } from '@/components/dialogs/DialogAnnotationProjectAdd.vue'
import { DialogFeatureRequestForm } from '@/components/dialogs/DialogFeatureRequest.vue'
import { DialogImageVersionAddDefaultForm } from '@/components/dialogs/DialogImageVersionAdd.vue'
import { DialogImageVersionEditImageVersion } from '@/components/dialogs/DialogImageVersionEdit.vue'
import { DialogKubeConfigEditDefaultForm } from '@/components/dialogs/DialogKubeConfigEdit.vue'
import { DialogLabelAddDefaultForm } from '@/components/dialogs/DialogLabelAdd.vue'
import { DialogLabelRemoveDefaultForm } from '@/components/dialogs/DialogLabelRemove.vue'
import { DialogMalfunctionReportForm } from '@/components/dialogs/DialogMalfunctionReport.vue'
import { DialogOrganisationAddDefaultForm } from '@/components/dialogs/DialogOrganisationAdd.vue'
import { DialogProjectAddDefaultForm } from '@/components/dialogs/DialogProjectAdd.vue'
import { DialogReportAddDefaultForm } from '@/components/dialogs/DialogReportAdd.vue'
import { DialogResetPasswordDefaultForm } from '@/components/dialogs/DialogResetPassword.vue'
import { DialogRetrainModelDefaultForm } from '@/components/dialogs/DialogRetrainModel.vue'
import { Books } from '@/stores/index.d'

import { TBookAddDefaultForm } from './bookAdd'

export interface FormEmits {
  (event: 'update:visible', value: boolean): void
}

interface VFormRuleWithRegex extends VFormRule {
  regexes?: Record<string, string>
}

type DefaultForm =
  | DialogAnnotationProjectAddForm
  | DialogAnnotationTabularRegressionDefaultForm
  | DialogAnnotationTextCaptionDefaultForm
  | DialogFeatureRequestForm
  | DialogImageVersionAddDefaultForm
  | DialogImageVersionEditImageVersion
  | DialogKubeConfigEditDefaultForm
  | DialogLabelAddDefaultForm
  | DialogLabelRemoveDefaultForm
  | DialogMalfunctionReportForm
  | DialogOrganisationAddDefaultForm
  | DialogProjectAddDefaultForm
  | DialogReportAddDefaultForm
  | DialogResetPasswordDefaultForm
  | DialogRetrainModelDefaultForm

export function useForm<TDefaultForm extends Record<string, unknown> | DefaultForm | TBookAddDefaultForm>(defaultForm: () => TDefaultForm, rulesKey: keyof typeof stores.jsons.validation, emit: FormEmits, book?: Books) {
  const form = reactive<TDefaultForm>(defaultForm())
  const validation = stores.jsons.validation
  // const uniques: Record<keyof typeof validation, Record<string, string[]>> = {}

  const uniques: { [key in keyof typeof validation]?: Record<string, string[]> } = {}

  /**
   * Retrieves rules from storage file. Creates a custom validator for uniqueness checking rules.
   * @param key Type of the object to validate.
   * @param book Pass this when editing an object and using uniqueness checking so its own values can be excluded.
   */
  async function getRules(key: keyof typeof validation, book?: Books) {
    // Retrieve rules from storage.
    const rules: Record<string, VFormRuleWithRegex> = filterObjectByKeyInArray(copyObject(validation[key]), Object.keys(form))
    // Check if rules need specific Javascript treatment.
    for (const [ruleName, rule] of Object.entries(rules)) {
      if ('unique' in rule && rule.unique) {
        rule.validators = rule?.validators ?? []
        // Retrieve array of unique values for a field.
        const { data } = await api.post<{ items: string[] }>('unique-items', { forms: { key: ruleName, library: key } })
        Object.assign(uniques, { [key]: { [ruleName]: data.items.map((uniqueItem: string) => uniqueItem.toLowerCase()) } })
        rule.validators.push({
          // Return `true` if check is passed, `false` to create an error.
          validator: (value) => {
            const values = copyObject(uniques?.[key]?.[ruleName])
            if (!values) return true
            // If a book was passed, remove its own value from the uniqueness checking array.
            if (book) {
              let existingValue: string | number = book[ruleName as keyof typeof book]
              if (typeof existingValue === 'string') existingValue = existingValue.toLowerCase()
              const index = values.findIndex((item) => item === existingValue)
              values.splice(index, 1)
            }
            return !values.includes(String(value).toLowerCase())
          },
          message: `${capitalise(singularise(key))} name already in use.`,
        })
      }
      if ('regexes' in rule && rule.regexes) {
        rule.validators = rule?.validators ?? []
        for (const [regexString, regexName] of Object.entries(rule.regexes)) {
          rule.validators.push({
            // Return `true` if check is passed, `false` to create an error.
            validator: (value) => {
              return RegExp(regexString).test(value)
            },
            message: regexName,
          })
        }
      }
      delete rule.regexes
      delete rule.type
    }
    return rules
  }

  const rules: Ref<Awaited<ReturnType<typeof getRules>>> = ref({})

  onBeforeMount(async () => {
    rules.value = await getRules(rulesKey, book)
  })

  /**
   * Tell the parent to hide this dialog.
   *
   * @emits update:visible
   */
  async function hide() {
    emit('update:visible', false)
    await delay(300)
    setDefaultForm()
  }

  function setDefaultForm() {
    Object.assign(form, defaultForm())
  }

  return { form, getRules, hide, rules, setDefaultForm }
}

import { StoreDefinition } from 'pinia'

import { Relation, StateBook, StoreRelation } from '.'
import { debounceOption, StandardBook, useStore } from './composables'
import { ExperimentGroup, ExperimentGroupRelation } from './experimentgroups'
import { Book } from './index.d'
import { OrganisationRelation } from './organisation'
import { Project, ProjectRelation } from './projects'
import { Status } from './statuses'
import { UserRelation } from './users'

interface ExperimentChildren {
  firenze_organisation: OrganisationRelation
  firenze_experiment_group: ExperimentGroupRelation
  firenze_project: ProjectRelation
  firenze_user: UserRelation
}

export type ExperimentRelation = Relation<Experiment, Appends, ExperimentChildren>

export interface ExperimentsQuery {
  pk: number
}

interface Appends {
  status: ExperimentStatus
}

const defaultQuery = {
  default: {},
}

interface ExperimentStatus {
  data: Status
  eda: Status
  training: Status
  inference: Status
  evaluation: Status
}

interface ExperimentMetrics {
  training: {
    serial?: {
      mean_accuracy: number[]
      time: string[]
    }
    singular?: {
      mean_accuracy: number
      time: string
    }
  }
  evaluation?: {
    serial: {
      mean_accuracy: number[]
      time: string[]
    }
    singular: {
      mean_accuracy: number
      time: string
    }
  }
}

interface ExperimentParameters {
  evaluation: {
    serial: object
    singular: object
  }
  training: {
    serial: object
    singular: object
  }
}

export interface Experiment extends Book {
  LIBRARY: 'experiments'
  configs: object
  description: string
  entry_name: string
  experiment_group_type: string
  firenze_experiment_group: ExperimentGroup
  firenze_experiment_group_id: number
  firenze_project: Project
  firenze_project_id: number
  metrics: ExperimentMetrics
  parameters: ExperimentParameters
  priority: number
  reports: object
}

export function createExperimentsStore<T extends StoreRelation<T, ExperimentRelation, Experiment, ExperimentChildren, Appends>>(id: string, initialRelations: T) {
  const library = 'experiments'
  type StoreExperiment = StateBook<T['fields'], T['appends'], T['hidden_fields'], T['children'], Experiment, Appends, ExperimentChildren, typeof library>

  return defineStore(
    id,
    () => {
      const experiments = ref<StoreExperiment[]>([])
      const updateAvailable = ref(false)

      const relations = ref<ExperimentRelation>({ ...initialRelations, query: defaultQuery })
      const { fetch, listenOnSocket, meta, relations: _relations, remove, resetQuery, sendRequest, update, updateQuery, updateSort } = useStore(library, relations, experiments as Ref<StandardBook[]>, updateAvailable)

      return { experiments, fetch, listenOnSocket, meta, relations, remove, resetQuery, sendRequest, update, updateAvailable, updateQuery, updateSort }
    },
    { ...debounceOption() }
  )
}

export const useExperimentsStore = createExperimentsStore('experiments', {})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useExperimentsStore as unknown as StoreDefinition, import.meta.hot))
}

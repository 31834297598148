import { StoreDefinition } from 'pinia'

import { Relation, StateBook, StoreRelation } from '.'
import { debounceOption, StandardBook, useStore } from './composables'
import { DataVersion, DataVersionRelation } from './dataversions'
import { ExperimentGroup, ExperimentGroupRelation } from './experimentgroups'
import { Experiment, ExperimentRelation } from './experiments'
import { Book } from './index.d'
import { Model, ModelRelation } from './models'
import { OrganisationRelation } from './organisation'
import { Portfolio, PortfolioRelation } from './portfolios'
import { Status } from './statuses'
import { ProjectsSupportedTechnique } from './types'
import { UserRelation } from './users'

interface ProjectChildren {
  firenze_evaluation_data_versions: DataVersionRelation
  firenze_main_evaluation_data_version: DataVersionRelation
  firenze_organisation: OrganisationRelation
  firenze_portfolios: PortfolioRelation
  firenze_teacher_models: ModelRelation
  firenze_training_data_versions: DataVersionRelation
  firenze_user: UserRelation
  firenzeexperimentgroup_set: ExperimentGroupRelation
  firenzeexperiment_set: ExperimentRelation
}

export interface ProjectsQuery {
  archived: boolean
  languages__contains: string[]
  entry_name__icontains: string
  status__in: string[]
  technique__in: string[]
  firenze_user_ids__in: number[]
  firenzeevaluationdataversions_set__firenze_datum__pk: number
  firenzetrainingdataversions_set__firenze_datum__pk: number
}

const defaultQuery = {
  default: {
    archived: false,
  },
}

interface ProjectStatus {
  orchestration: Status
  evaluation: Status
}

interface Appends {
  status: ProjectStatus
}

export interface Project extends Book {
  LIBRARY: 'projects'
  archived: boolean
  description: string
  documentation: string
  entry_name: string
  firenze_evaluation_data_versions_ids: number[]
  firenze_evaluation_data_versions: DataVersion[]
  firenze_main_evaluation_data_version_id: number
  firenze_main_evaluation_data_version: DataVersion
  firenze_portfolios_ids: number[]
  firenze_portfolios: Portfolio[]
  firenze_teacher_models_ids: number[]
  firenze_teacher_models: Model[]
  firenze_training_data_versions_ids: number[]
  firenze_training_data_versions: DataVersion[]
  firenzeexperiment_set: Experiment[]
  firenzeexperimentgroup_set: ExperimentGroup[]
  languages: (keyof typeof stores.jsons.renames.languages)[]
  parsed_format: keyof typeof stores.jsons.renames.parsed_formats
  project_status: keyof typeof stores.jsons.renames.status
  technique: ProjectsSupportedTechnique
}

export type ProjectRelation = Relation<Project, Appends, ProjectChildren>

export function createProjectsStore<T extends StoreRelation<T, ProjectRelation, Project, ProjectChildren, Appends>>(id: string, initialRelations: T) {
  const library = 'projects'
  type StoreProject = StateBook<T['fields'], T['appends'], T['hidden_fields'], T['children'], Project, Appends, ProjectChildren, typeof library>

  return defineStore(
    id,
    () => {
      const projects = ref<StoreProject[]>([])
      const updateAvailable = ref(false)

      const relations = ref<ProjectRelation>({ ...initialRelations, query: defaultQuery })
      const { fetch, listenOnSocket, meta, relations: _relations, remove, resetQuery, sendRequest, update, updateQuery, updateSort } = useStore(library, relations, projects as Ref<StandardBook[]>, updateAvailable)

      return { projects, fetch, listenOnSocket, meta, relations, remove, resetQuery, sendRequest, update, updateAvailable, updateQuery, updateSort }
    },
    { ...debounceOption() }
  )
}

export const useProjectsStore = createProjectsStore('projects', {
  appends: ['status'],
  fields: ['project_status', 'last_updated', 'entry_name', 'technique', 'parsed_format'],
  children: {
    firenze_user: {
      fields: ['first_name', 'last_name'],
      appends: ['full_name'],
    },
  },
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useProjectsStore as unknown as StoreDefinition, import.meta.hot))
}

import { StoreDefinition } from 'pinia'

import { Library } from '@/types'

import { Relation, StateBook, StoreRelation } from '.'
import { debounceOption, StandardBook, useStore } from './composables'
import { Book } from './index.d'
import { OrganisationRelation } from './organisation'
import { UserRelation } from './users'

interface ReportChildren {
  firenze_organisation: OrganisationRelation
  firenze_user: UserRelation
}

export type ReportRelation = Relation<Report, Appends, ReportChildren>

export interface ReportsQuery {
  pk: number
  entry_name__icontains: string
  libraries__in: Library[]
}

interface Appends {
  parent_entry_name: string
}

const defaultQuery = {
  default: {},
}

export interface Report extends Book {
  LIBRARY: 'reports'
  book_id: number
  book_library: Library
  entry_name: string
  lifecycle: keyof typeof stores.jsons.renames.lifecycles
  report_status: keyof typeof stores.jsons.renames.status
}

export function createReportsStore<T extends StoreRelation<T, ReportRelation, Report, ReportChildren, Appends>>(id: string, initialRelations: T) {
  const library = 'reports'
  type StoreReport = StateBook<T['fields'], T['appends'], T['hidden_fields'], T['children'], Report, Appends, ReportChildren, typeof library>

  return defineStore(
    id,
    () => {
      const reports = ref<StoreReport[]>([])
      const updateAvailable = ref(false)

      const relations = ref<ReportRelation>({ ...initialRelations, query: defaultQuery })
      const { fetch, listenOnSocket, meta, relations: _relations, remove, resetQuery, sendRequest, update, updateQuery, updateSort } = useStore(library, relations, reports as Ref<StandardBook[]>, updateAvailable)

      return { reports, fetch, listenOnSocket, meta, relations, remove, resetQuery, sendRequest, update, updateAvailable, updateQuery, updateSort }
    },
    { ...debounceOption() }
  )
}

export const useReportsStore = createReportsStore('reports', {
  appends: ['parent_entry_name'],
  children: {
    firenze_user: {
      appends: ['full_name'],
    },
  },
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useReportsStore as unknown as StoreDefinition, import.meta.hot))
}

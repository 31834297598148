import { AxiosError } from 'axios'
import { StoreDefinition } from 'pinia'

import router, { createRouterGuards } from '@/router'
import { Env } from '@/types'

import { augmentations as augmentationsImport } from '../../storage/app/augmentations'
import techniquesImport from '../../storage/app/techniques.json'
import { trainModels as trainModelsImport } from '../../storage/app/trainModels'
import { GlobalUser } from './user'
import { UserSettings } from './usersettings'

export const useInitialStateStore = defineStore('initialState', {
  state: () => ({
    env: {
      // app_name: (document.head.querySelector('meta[name="app-name"]') as HTMLMetaElement).content,
      client_version: '',
      download_url: '',
      sdk_version: '',
      app_env: '',
      platform: '',
      version: '',
      support_email: '',
      url_cdn_firenze: '',
      url_changelog: '',
      url_product_documentation: '',
      url_whats_new: '',
      sentry: {
        dsn: '',
      },
    } as Env,
    user: {} as GlobalUser,
    jsons: {
      augmentations: {},
      trainModels: {},
      techniques: {},
    } as { augmentations: typeof augmentationsImport; trainModels: typeof trainModelsImport; techniques: typeof techniquesImport },
    defaultUserSettings: {} as UserSettings,
    fieldMapping: {} as Record<string, string>,
    unreadNotificationsCount: 0,
    objectless: false,
    userSettings: {} as UserSettings,
  }),

  actions: {
    async load(login = false) {
      await stores.loading.update({ app: true })
      await this.loadInitialState(login)

      if (!stores.user.authenticated) {
        await stores.loading.update({ app: false })
        return
      }

      createRouterGuards()

      const data = copyObject(this.$state)

      if (env.sentry.dsn) {
        sentry.setUser(data.user)
        sentry.setDsn(env.sentry.dsn)
        sentry.setRelease(env.version ?? '')
        sentry.setEnv(env.app_env ?? '')
        sentry.run()
      }

      Object.assign(stores.jsons, data.jsons)

      stores.user.load(data.user)
      stores.usersettings.load(data.userSettings, data.defaultUserSettings)
      await stores.organisation.load(this.user.firenze_organisation_id)
      await stores.users.fetch({ paginate: false })

      socket.organisation.listen(`organisation/${this.user.firenze_organisation_id}`)
      socket.user.listen(`${this.user.firenze_organisation_id}/user/${this.user.id}`)

      socket.user.on<{ data: { status: string } }>('form_submitted', ({ data }) => {
        if (data.status === 'success') {
          $message.success('Your report was successfully submitted')
        } else {
          $message.error('Failed to submit the report')
        }
        bus.emit('report-submitted-stop-loading')
      })

      await stores.loading.update({ app: false })
    },

    async loadInitialState(login: boolean) {
      try {
        const data = await getInitialState()
        this.$state = data
        await stores.admin.fetch()

        window.env = app.config.globalProperties.$env = this.env
        stores.user.authenticated = true

        const route = router.currentRoute

        if (login && !data.user.has_objects && data.user.permissions.includes('ViewObjects')) {
          stores.initialState.objectless = true
        }

        if (route.value.query?.next) {
          await router.push(route.value.query.next as string)
        } else if (route.value.meta.guest && !route.value.meta.allowSignedIn) {
          await router.replace('/')
        }
        if (route.value.meta.title) {
          document.title = route.value.meta.title as string
        }
      } catch (error) {
        stores.user.authenticated = false
        if (!(error instanceof AxiosError)) {
          throw error
        }

        // For some reason, vue router does not contain the current route, so we set the current route from the url.
        await router.replace(window.location.hash.replace('#', ''))
        const route = router.currentRoute
        if (!route.value.meta.guest && route.value.path !== '/login') {
          await router.push({ path: '/login', query: { next: route.value.fullPath } })
        }
      }
    },
  },
})

async function getInitialState(): Promise<ReturnType<typeof useInitialStateStore>['$state']> {
  if (window.initialStatePromise) {
    try {
      const response = await window.initialStatePromise
      delete window.initialStatePromise
      return response.data
    } catch (error) {
      delete window.initialStatePromise
      throw error
    }
  } else {
    window.initialStatePromise = api.get('initial-state')
    return await getInitialState()
  }
}

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useInitialStateStore as unknown as StoreDefinition, import.meta.hot))
}

<template>
  <VDialog ref="dialog" :placement="placement" :title="title" :visible="visible" :width="width" @update:visible="$emit('update:visible', false)">
    <VForm ref="formRef" :form="form" :rules="rules" :validate-on="validateOn" @submit="$emit('submit')" @update:errors="$emit('update:errors', $event)">
      <VLoadingDots v-if="loading" mask />
      <slot />
    </VForm>
    <template v-if="!hideFooter" #footer>
      <slot name="footer" :validate="validate">
        <VButton type="secondary" @click="$emit('update:visible', false)">{{ cancelText }}</VButton>
        <VButton :disabled="disabledHelp?.disabled" :help="disabledHelp?.help" :loading="loading" @click="validate()">{{ submitText }}</VButton>
      </slot>
    </template>
  </VDialog>
</template>

<script setup lang="ts">
import { DisabledHelp, VDialogPlacement, VForm, VFormRule, VFormTrigger } from '@vartion/ui'

interface Props {
  cancelText?: string
  disabledHelp?: DisabledHelp
  errors?: Record<string, string[]>
  form?: Record<string, unknown>
  hideFooter?: boolean
  loading?: boolean
  placement?: VDialogPlacement
  rules?: Record<string, VFormRule>
  submitText?: string
  title?: string
  validateOn?: VFormTrigger
  visible?: boolean
  width?: string
}

const props = withDefaults(defineProps<Props>(), {
  cancelText: 'Cancel',
  disabledHelp: undefined,
  errors: undefined,
  form: () => ({}),
  hideFooter: false,
  loading: false,
  placement: 'right',
  rules: () => ({}),
  submitText: 'Submit',
  title: '',
  validateOn: 'keyup',
  width: '40%',
})

const formRef = ref<InstanceType<typeof VForm>>()

const emit = defineEmits<{
  (event: 'update:visible', visible: boolean): void
  (event: 'submit'): void
  (event: 'update'): void
  (event: 'update:errors', errors: Record<string, string[]>): void
}>()

watch(
  () => props.visible,
  (value) => {
    if (!value && formRef.value) {
      formRef.value.localErrors = {}
      emit('update:errors', {})
    }
  }
)

function validate() {
  if (formRef.value) formRef.value.submit()
}

defineExpose({
  formRef,
})
</script>

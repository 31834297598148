import { StoreDefinition } from 'pinia'

import { Library } from '@/types'

const createState = (): Record<Library, boolean> & { app: boolean; user: boolean } => ({
  admin: false,
  annotationannotations: false,
  annotationdata: false,
  annotationlabels: false,
  annotationprojects: false,
  annotationtexts: false,
  app: true,
  data: false,
  dataversions: false,
  dataversionscontrols: false,
  experimentgroups: false,
  experiments: false,
  groups: false,
  imageversionsdataparsing: false,
  imageversionsktrain: false,
  imageversionslongformer: false,
  jobs: false,
  kubeconfigs: false,
  modellogexplanations: false,
  modellogexplanationtexts: false,
  models: false,
  modelscontrols: false,
  organisation: false,
  organisations: false,
  portfolios: false,
  portfolioscontrols: false,
  projects: false,
  reports: false,
  statuses: false,
  user: false,
  users: false,
  usersettings: false,
  imageversionsluke: false,
  imageversionssklearn: false,
  imageversionssktime: false,
  imageversionsspacy: false,
})

export function createLoadingStore(id: string) {
  return defineStore(id, {
    state: () => createState() as Record<keyof ReturnType<typeof createState>, boolean>,

    actions: {
      async update(data: Partial<ReturnType<typeof createState>>) {
        Object.assign(this.$state, data)

        await nextFrame()
      },
    },
  })
}

export const useLoadingStore = createLoadingStore('loading')

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useLoadingStore as unknown as StoreDefinition, import.meta.hot))
}

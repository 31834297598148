<template>
  <div>
    <VTable :data="tableData" :expandable-column="false" hide-header :loading="loading" row-key="id">
      <VTableColumn v-slot="{ row }: { row: Notification }" wrap-text>
        <div v-text="message(row)" />
        <SimplifyDate class="color-grey-40" :date="row.date_of_creation" style="font-size: 12px" />
      </VTableColumn>

      <VTableColumn v-slot="{ row }: { row: Notification }" cell-class="float-right" width="120px">
        <VButton label="Open" size="small" @click="open(row)" />
      </VTableColumn>

      <template #empty>{{ read ? 'No read messages' : 'No unread messages' }}</template>
    </VTable>

    <VPagination v-model="page" :total="total" @update:model-value="fetch" />
  </div>
</template>

<script setup lang="ts">
import { Notification, notifications, open } from '@/services/notifications'

interface Props {
  read?: boolean
}

const props = defineProps<Props>()

const { isActivated } = useIsActivated()
const tableData = ref<Notification[]>([])
const loading = ref(false)
const page = ref(1)
const total = ref(0)

fetch()
onActivated(() => fetch())
bus.on('notify', () => (isActivated.value ? fetch() : ''))
onBeforeUnmount(() => bus.off('notify'))

async function fetch() {
  loading.value = true
  try {
    const { data } = await api.post('notifications/searches', {
      page: page.value,
      page_size: 10,
      relations: { query: { query_name: { read_at__isnull: !props.read } } },
    })
    tableData.value = data.data
    total.value = data.meta.count
    if (!props.read) {
      stores.initialState.unreadNotificationsCount = total.value
    }
  } finally {
    loading.value = false
  }
}

function message(notification: Notification) {
  return notifications.createMessage(notification)
}

defineExpose({
  fetch,
  tableData,
  page,
  total,
})
</script>

import { StoreDefinition } from 'pinia'

import { Relation, StateBook, StoreRelation } from '.'
import { debounceOption, StandardBook, useStore } from './composables'
import { DataVersion, DataVersionRelation } from './dataversions'
import { ExperimentGroup, ExperimentGroupRelation } from './experimentgroups'
import { Book } from './index.d'
import { Model, ModelRelation } from './models'
import { OrganisationRelation } from './organisation'
import { Portfolio, PortfolioRelation } from './portfolios'
import { Project, ProjectRelation } from './projects'
import { Status, StatusRelation } from './statuses'
import { ActionChoices } from './types'
import { UserRelation } from './users'

interface JobChildren {
  firenze_organisation: OrganisationRelation
  firenze_user: UserRelation
  firenze_model: ModelRelation
  firenze_experiment_group: ExperimentGroupRelation
  firenze_data_version: DataVersionRelation
  firenze_project: ProjectRelation
  firenze_portfolio: PortfolioRelation
  firenzestatus_set: StatusRelation
}

export type JobRelation = Relation<Job, Appends, JobChildren>

export interface JobsQuery {
  pk: number
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface Appends {}

const defaultQuery = {
  default: {},
}

export interface Job extends Book {
  LIBRARY: 'jobs'
  action: ActionChoices
  containers: object
  entry_name: string
  firenze_data_version_id: number
  firenze_data_version: DataVersion
  firenze_experiment_group_id: number
  firenze_experiment_group: ExperimentGroup
  firenze_model_id: number
  firenze_model: Model
  firenze_project_id: number
  firenze_project: Project
  firenze_portfolio_id: number
  firenze_portfolio: Portfolio
  firenzestatus_set: Status[]
  init_containers: object
  linked_library: 'models' | 'experimentgroups' | 'dataversions' | 'projects' | 'portfolios'
  logs: string
  priority: string
  selection_labels: object[]
  uuid: string
}
export function createJobsStore<T extends StoreRelation<T, JobRelation, Job, JobChildren, Appends>>(id: string, initialRelations: T) {
  const library = 'jobs'
  type StoreJob = StateBook<T['fields'], T['appends'], T['hidden_fields'], T['children'], Job, Appends, JobChildren, typeof library>

  return defineStore(
    id,
    () => {
      const jobs = ref<StoreJob[]>([])
      const updateAvailable = ref(false)

      const relations = ref<JobRelation>({ ...initialRelations, query: defaultQuery })
      const { fetch, listenOnSocket, meta, relations: _relations, remove, resetQuery, sendRequest, update, updateQuery, updateSort } = useStore(library, relations, jobs as Ref<StandardBook[]>, updateAvailable)

      return { jobs, fetch, listenOnSocket, meta, relations, remove, resetQuery, sendRequest, update, updateAvailable, updateQuery, updateSort }
    },
    { ...debounceOption() }
  )
}

export const useJobsStore = createJobsStore('jobs', {
  hidden_fields: ['logs'],
  children: {
    firenze_user: {
      fields: ['first_name', 'last_name'],
      appends: ['full_name'],
    },
    firenze_data_version: {
      fields: ['entry_name'],
    },
    firenze_experiment_group: {
      fields: ['entry_name'],
    },
    firenze_model: {
      fields: ['entry_name'],
    },
    firenze_project: {
      fields: ['entry_name'],
    },
    firenze_portfolio: {
      fields: ['entry_name'],
    },
    firenzestatus_set: {
      fields: ['status'],
    },
  },
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useJobsStore as unknown as StoreDefinition, import.meta.hot))
}

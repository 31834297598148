import { StoreDefinition } from 'pinia'

import { Optional } from '@/types'

import { Relation, StateBook, StoreRelation } from '.'
import { AnnotationProject, AnnotationProjectRelation } from './annotationprojects'
import { debounceOption, StandardBook, useStore } from './composables'
import { Datum, DatumRelation } from './data'
import { Book } from './index.d'
import { Model, ModelRelation } from './models'
import { OrganisationRelation } from './organisation'
import { Status } from './statuses'
import { UserRelation } from './users'

interface DataVersionChildren {
  firenze_organisation: OrganisationRelation
  firenze_datum: DatumRelation
  firenze_annotation_project: AnnotationProjectRelation
  firenze_annotated_from_version: DataVersionRelation
  firenze_inferred_from_version: DataVersionRelation
  firenze_inferred_on: ModelRelation
  firenze_user: UserRelation
  firenzeannotationproject_set: DataVersionRelation
}

export type DataVersionRelation = Relation<DataVersion, Appends, DataVersionChildren>

export interface DataVersionsQuery {
  pk: number
}

interface Appends {
  status: DataVersionStatus
  downloads: number
  object_usage: Record<string, number>
  controls_resolved: string
  file_size: number
}

const defaultQuery = {
  default: {},
}

interface DataVersionStatus {
  orchestration: Status
  parsing: Status
  pandas_profiling: Status
  analyse_text_column: Status
  tsne: Status
  umap: Status
  intercluster: Status
  distribution_plot: Status
}

interface Reports {
  analyse_text_column: {
    max_length: number
    mean_length: number
    median_length: number
    min_length: number
    number_of_texts: number
    number_of_unique_texts: number
    unique_percentage: number
  }
  analyse_text_column_plot: string
  distribution_plot: Record<string, number>
  distribution_plot_image: string
  intercluster_plot: string
  parsed_eda_report: string
  tsne_plot: string
  umap_plot: string
}

export const dataVersionDataNotificationRenames = {
  custom_column_mismatch_error: 'This data version has a mismatch in columns present in the data.',
  custom_deeprank_text_column_error: 'Longformer requires the training column to be "text".',
  custom_not_yet_parsed_error: 'This data version is not yet parsed.',
  evaluation_too_little_data_regression_error: 'Too little evaluation data. Can not evaluate a model with less than 10 rows.',
  evaluation_too_little_data_regression_warning: 'Low number of data points in evaluation data. This can give a wrong model performance impression. It is advised to use more than 100 rows.',
  evaluation_too_little_data_classification_error: 'Too little evaluation data. Can not evaluate a model with less than 10 samples per class.',
  evaluation_too_little_data_classification_warning: 'Low number of data points in evaluation data. This can give a wrong model performance impression. It is advised to use more than 50 data samples per class.',
  general_unbalanced_data_warning: 'Unbalanced data. Not all models can handle unbalanced data. This can result in poor model performance.',
  training_too_little_data_regression_error: 'Too little training data. Can not train a model with less than 100 rows.',
  training_too_little_data_regression_warning: 'Low number of data points in training data. This can result in poor model performance. It is advised to use more than 250 rows.',
  training_too_little_data_classification_error: 'Too little training data. Can not train a model with less than 25 samples per class.',
  training_too_little_data_classification_warning: 'Low number of data points in training data. This can result in poor model performance. It is advised to use more than 100 data samples per class.',
}

export type DataVersionDataNotification = keyof typeof dataVersionDataNotificationRenames

export interface DataVersion extends Book {
  LIBRARY: 'dataversions'
  archived: boolean
  columns: Record<string, 'str' | 'float' | 'int'>
  description: string
  documentation: string
  entry_name: string
  file_name: string
  firenze_data_version_storage_object_id: number
  parsed: boolean
  predict_column: Record<string, 'str' | 'float' | 'int'>
  train_columns: Record<string, 'str' | 'float' | 'int'>
  firenze_annotated_from_version_id: number
  firenze_annotated_from_version: DataVersion
  firenze_annotation_project_id: number
  firenze_annotation_project: AnnotationProject
  firenze_datum_id: number
  firenze_datum: Datum
  firenze_inferred_from_version_id: number
  firenze_inferred_from_version: DataVersion
  firenzeannotationproject_set: DataVersion[]
  firenzeannotationproject_ids: number[]
  firenze_inferred_on_id: number
  firenze_inferred_on: Model
  firenze_data_version_parsed_storage_object_id: number
  parsing_time: Optional<number>
  reports: Reports
  object_usage: Record<string, number>
  requirements: string
  supported_labels: string[]
  version: number
  data_errors: DataVersionDataNotification[]
  data_warnings: DataVersionDataNotification[]
}

export function createDataVersionsStore<T extends StoreRelation<T, DataVersionRelation, DataVersion, DataVersionChildren, Appends>>(id: string, initialRelations: T) {
  const library = 'dataversions'
  type StoreDataVersion = StateBook<T['fields'], T['appends'], T['hidden_fields'], T['children'], DataVersion, Appends, DataVersionChildren, typeof library>

  return defineStore(
    id,
    () => {
      const dataversions = ref<StoreDataVersion[]>([])
      const updateAvailable = ref(false)

      const relations = ref<DataVersionRelation>({ ...initialRelations, query: defaultQuery })
      const { fetch, listenOnSocket, meta, relations: _relations, remove, resetQuery, sendRequest, update, updateQuery, updateSort } = useStore(library, relations, dataversions as Ref<StandardBook[]>, updateAvailable)

      return { dataversions, fetch, listenOnSocket, meta, relations, remove, resetQuery, sendRequest, update, updateAvailable, updateQuery, updateSort }
    },
    { ...debounceOption() }
  )
}

export const useDataVersionsStore = createDataVersionsStore('dataversions', { appends: ['status', 'object_usage'] })

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useDataVersionsStore as unknown as StoreDefinition, import.meta.hot))
}

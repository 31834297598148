import { VFormRule } from '@vartion/ui'
import { StoreDefinition } from 'pinia'

// import { TrainModelsOptions, TrainModelsTechnique } from '@/types'
import { augmentations as augmentationsImport } from '../../storage/app/augmentations'
import { fields as fieldsImport } from '../../storage/app/fields'
import gettingStartedImport from '../../storage/app/getting_started.json'
import renamesImport from '../../storage/app/renames.json'
import { techniques as techniquesImport } from '../../storage/app/techniques'
import { trainModels as trainModelsImport } from '../../storage/app/trainModels'
import validationImport from '../../storage/app/validation.json'
// import { Technique } from './types'

// export const a: Record<Technique, Record<string, TrainModelsTechnique>> = trainModelsImport
// export const b: Record<string, TrainModelsTechnique> = trainModelsImport['text-classification']
// export const c: TrainModelsTechnique = trainModelsImport['text-classification']['adaboostclassifier-sklearn']
// export const d: TrainModelsTechnique['hyperopthyperparams'] = trainModelsImport['text-classification']['adaboostclassifier-sklearn'].hyperopthyperparams
// export const e: TrainModelsOptions<'hyperopthyperparams', true> = trainModelsImport['text-classification']['adaboostclassifier-sklearn'].hyperopthyperparams.algorithm
// export const f: TrainModelsOptions<'hyperopthyperparams', true>['settings'] = trainModelsImport['text-classification']['adaboostclassifier-sklearn'].hyperopthyperparams.algorithm.settings

// export const a: Record<string, VFormRule> = validationImport
// export const b: Record<string, VFormRule> = validationImport.featurerequests
// export const c: VFormRule = validationImport.featurerequests.follow_up_email

export function createJsonsStore(id: string) {
  return defineStore(id, () => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const augmentations = ref<typeof augmentationsImport>({})
    const fields = ref(fieldsImport)
    const gettingStarted = ref(gettingStartedImport)
    const renames = ref(renamesImport)
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const techniques = ref(techniquesImport)
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const trainModels = ref<typeof trainModelsImport>({})
    const validation = ref(validationImport)
    return {
      augmentations,
      fields,
      gettingStarted,
      renames,
      techniques,
      trainModels,
      validation,
    }
  })
}

export const useJsonsStore = createJsonsStore('jsons')

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useJsonsStore as unknown as StoreDefinition, import.meta.hot))
}

<template>
  <div class="sidebar border-right">
    <div>
      <div class="sidebar-item" style="border-top: none">
        <!-- <img alt="Logo" src="/images/firenze_logo_symbol2.png" style="height: 30px" @click="$router.push('/')" /> -->
        <div class="firenze-logo" @click="$router.push('/')">
          <div class="square" />
          <div class="diamond" />
        </div>
      </div>

      <div v-if="$can('SuperUser')" class="sidebar-item" @click="$router.push('/administration')">
        <VIcon icon="Cog" :size="24" type="info" />
      </div>

      <div class="sidebar-item" @click="$bus.emit('dialog.show', 'notifications')">
        <VBadge border :hidden="!$stores.initialState.unreadNotificationsCount" :size="12" type="secondary">
          <VIcon icon="Alarm" :size="24" type="info" />
        </VBadge>
      </div>
    </div>

    <div class="bottom">
      <div class="sidebar-item" @click="dialogSupportVisible = true">
        <VIcon icon="Support" :size="24" type="info" />
      </div>
    </div>

    <DialogSupport v-model:visible="dialogSupportVisible" />
  </div>
</template>

<script setup lang="ts">
const dialogSupportVisible = ref(false)
</script>

<style lang="scss" scoped>
.sidebar {
  background-color: #fff;
  height: 100vh;
  width: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  > div {
    width: 100%;
  }

  .sidebar-item {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 15px 0;
    border-top: var(--border);
    cursor: pointer;
    .firenze-logo {
      display: flex;
      height: 30px;
      width: 30px;
      justify-content: center;
      align-items: center;
      .square {
        position: absolute;
        background-color: var(--color-tertiary);
        height: 20px;
        width: 20px;
        left: 25px;
        top: 15px;
      }
      .diamond {
        position: absolute;
        // background-color: red;
        border: 2px solid var(--color-secondary);
        height: 16px;
        width: 16px;
        left: 18px;
        top: 26px;
        transform: rotate(45deg);
      }
    }
  }
}

.v-popper__content {
  cursor: default;
}

.v-icon:hover :deep() * {
  stroke: #000;
}
</style>

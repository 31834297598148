<template>
  <VDialog placement="left" title="Need help?" :visible="visible" width="40%" @update:visible="$emit('update:visible', $event)">
    <VRow :gutter="20">
      <VCol :md="8">
        <div class="icon-button" @click="openExternal('product-documentation')">
          <VIcon icon="Document" :size="50" type="primary" />
          Documentation
        </div>
      </VCol>

      <VCol :md="8">
        <div class="icon-button" @click="openExternal('email')">
          <VIcon icon="PaperPlane" :size="50" type="primary" />
          Mail
        </div>
      </VCol>

      <VCol :md="8">
        <div class="icon-button" @click="openGettingStarted()">
          <VIcon icon="Search" :size="50" type="primary" />
          Getting started
        </div>
      </VCol>
    </VRow>

    <div class="title-30" style="margin-top: 60px; margin-bottom: 10px">Found an issue?</div>

    <VTableKeyValue value-width="25px">
      <VTableKeyValueRow @click="dialogMalfunctionReportVisible = true">
        <template #key>
          <div class="title-20">Malfunction report</div>
          When something isn't working
        </template>
        <div class="flex-center">
          <VButton icon="ChevronRight" :icon-size="20" rounded type="link" />
        </div>
      </VTableKeyValueRow>

      <VTableKeyValueRow @click="dialogFeatureRequestVisible = true">
        <template #key>
          <div class="title-20">Feature request</div>
          Have a great idea to make Firenze even better?
        </template>
        <div class="flex-center">
          <VButton icon="ChevronRight" :icon-size="20" rounded type="link" />
        </div>
      </VTableKeyValueRow>
    </VTableKeyValue>

    <VButton class="bottom-button" type="link" @click="openExternal('whats-new')">Firenze {{ env.app_env === 'production' ? '' : env.app_env }} {{ env.version }}</VButton>
    <DialogMalfunctionReport v-model:visible="dialogMalfunctionReportVisible" />
    <DialogFeatureRequest v-model:visible="dialogFeatureRequestVisible" />
  </VDialog>
</template>

<script setup lang="ts">
interface Props {
  visible: boolean
}

defineProps<Props>()

const emit = defineEmits<{
  (event: 'update:visible', value: boolean): void
}>()

const dialogMalfunctionReportVisible = ref(false)
const dialogFeatureRequestVisible = ref(false)

const env = computed(() => stores.initialState.env)

function openGettingStarted() {
  emit('update:visible', false)
  router.push({ path: '/getting-started' })
}
// openExternal(url) {
//   const a = document.createElement('a')
//   a.href = `redirect/${url}`
//   a.target = '_blank'
//   a.rel = 'noopener'
//   a.click()
//   a.remove()
// },
function openExternal(url: string) {
  // TODO(Rogier/Levi): Use Redirect Controller?
  const a = document.createElement('a')
  // a.href = `redirect/${url}`
  switch (url) {
    case 'malfunction-report':
      a.href = 'https://form.asana.com/?k=N5bRvBI1pLZz7V2nKbQf5w&d=494251200028853'
      break
    case 'feature-request':
      a.href = 'https://form.asana.com/?k=mAadbLLBauB5Tw8b_miLOg&d=494251200028853'
      break
    case 'whats-new':
      a.href = env.value.url_whats_new
      break
    case 'product-documentation':
      a.href = env.value.url_product_documentation
      break
    case 'email':
      a.href = `mailto:${env.value.support_email}`
      break
  }
  a.target = '_blank'
  a.rel = 'noopener'
  a.click()
  a.remove()
}
</script>

<style lang="scss" scoped>
.v-dialog-wrapper {
  left: 60px;
}
.icon-button {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-weight: 600;
  font-size: 20px;
  padding: 30px 15px 30px 15px;
  border-radius: 10px;
  transition: background-color var(--transition);
  cursor: pointer;
  &:hover {
    background-color: var(--color-blue-1);
  }
  & > .v-icon {
    margin-bottom: 15px;
  }
}
.bottom-button {
  position: fixed;
  bottom: 10px;
  background-color: #fff;
}
.v-table-key-value__row :deep() {
  cursor: pointer;
  .font-medium {
    font-weight: 400;
  }
  &:hover .v-btn {
    background-color: var(--color-blue-1);
  }
}
</style>

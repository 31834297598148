import { StoreDefinition } from 'pinia'

import { Library } from '@/types'

import { useDefaultRelations } from './composables'
const colourThemeMap = {
  blue: {
    primary: '#0017a8',
    secondary: '#0631ff',
    tertiary: '#00ffe0',
    red: '#d62121',
  },
  orange: {
    primary: '#ff7e06',
    secondary: '#ffa048',
    tertiary: '#ffcf48',
    red: '#900000',
  },
  purple: {
    primary: '#5f22d9',
    secondary: '#ac73ff',
    tertiary: '#c873ff',
    red: '#C30000',
  },
}

export interface UserSettings {
  annotation_article_alignment: 'left' | 'justify'
  annotation_categories_display: string
  annotation_font_size: 'extra small' | 'small' | 'medium' | 'large' | 'extra large'
  colour_hexes: (typeof colourThemeMap)[keyof typeof colourThemeMap]
  colour_theme: keyof typeof colourThemeMap
  notify_annotation_project_export_error: boolean
  notify_annotation_project_export_success: boolean
  notify_application_updated: boolean
  notify_auto_ml_project_created: boolean
  notify_data_parsed_error: boolean
  notify_data_parsed_success: boolean
  notify_data_version_exported_error: boolean
  notify_data_version_exported_success: boolean
  notify_model_evaluated_error: boolean
  notify_model_evaluated_success: boolean
  notify_model_explained_error: boolean
  notify_model_explained_success: boolean
  notify_model_hosting_stopped_idle: boolean
  notify_model_inferred_error: boolean
  notify_model_inferred_success: boolean
  notify_model_retraining_started_notification: boolean
  notify_model_started_training: boolean
  notify_model_trained_error: boolean
  notify_model_trained_success: boolean
  notify_multiple_annotation_projects_exported_error: boolean
  notify_multiple_annotation_projects_exported_success: boolean
  notify_object_assigned: boolean
  used_data_warning: boolean
}

export function createUserSettingsStore(id: string) {
  return defineStore(id, () => {
    const library: Library = 'usersettings'
    const usersettings = ref<UserSettings>()
    const defaultUserSettings = ref<UserSettings>()

    function listenOnSocket() {
      socket.user.on('update_store', ({ action, library, data }: { action: string; library: Library; data: object }) => {
        if (library !== library && action !== 'update') return
        update({ settings: data })
      })
    }

    function load(userSettingsUpdate: UserSettings, defaultUserSettingsUpdate: UserSettings) {
      defaultUserSettings.value = { ...defaultUserSettingsUpdate }
      usersettings.value = { ...defaultUserSettingsUpdate, ...userSettingsUpdate }
      updateTheme(usersettings.value.colour_theme)
      listenOnSocket()
    }

    async function update({ settings }: { settings: Partial<UserSettings> }) {
      await stores.loading.update({ usersettings: true })
      if (settings.colour_theme && settings.colour_theme !== usersettings.value?.colour_theme) updateTheme(settings.colour_theme)
      usersettings.value = { ...usersettings.value, ...settings } as UserSettings
      await stores.loading.update({ usersettings: false })
    }

    function updateTheme(colourTheme: keyof typeof colourThemeMap) {
      const theme = colourThemeMap[colourTheme]
      if (usersettings.value) usersettings.value.colour_hexes = theme
      const r = document.querySelector<HTMLElement>(':root')
      r?.style.setProperty('--color-primary', theme.primary)
      r?.style.setProperty('--color-secondary', theme.secondary)
      r?.style.setProperty('--color-tertiary', theme.tertiary)
      r?.style.setProperty('--color-red', theme.red)
    }
    return { library, load, update, usersettings }
  })
}
export const useUserSettingsStore = createUserSettingsStore('usersettings')

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useUserSettingsStore as unknown as StoreDefinition, import.meta.hot))
}

import { Dedupe } from '@sentry/integrations'
import * as Sentry from '@sentry/vue'
import { dayjs } from '@vartion/ui'

import { Cache } from '@/services/cache/Cache'

interface SentryUser {
  id: number
  email: string
  first_name: string
  last_name: string
}

export const sentry = {
  client: Sentry,
  cache: new Cache({ tags: ['sentry'] }),
  blacklist: ['cancel', 'close', 'Network Error', 'Validation failed'],
  user: null as SentryUser | null,
  dsn: '',
  env: '',
  release: '',

  setUser(user: SentryUser) {
    this.user = { email: user.email, id: user.id, first_name: user.first_name, last_name: user.last_name }
  },

  setDsn(dsn: string) {
    this.dsn = dsn
  },

  setRelease(version: string) {
    this.release = version
  },

  setEnv(env: string) {
    this.env = env
  },

  run() {
    if (!this.dsn) {
      throw new Error('DSN is required')
    }
    const vm = this
    Sentry.init({
      app,
      dsn: vm.dsn,
      environment: vm.env,
      release: vm.release,
      integrations: [new Dedupe()],
      ignoreErrors: [/ResizeObserver loop.*/i, /this.isExpired is not a function/i],
      beforeSend(event) {
        return vm.beforeSend(event)
      },
    })

    if (this.user) {
      Sentry.setUser({
        id: `${this.user.id}`,
        email: this.user.email,
        'First name': this.user.first_name,
        'Last name': this.user.last_name,
      })
    }
  },

  beforeSend(event: Sentry.Event) {
    event = this.shouldReport(event)
    event = this.rateLimit(event)

    if (event.exception?.values?.length === 0) {
      return null
    }

    return event
  },

  shouldReport(event: Sentry.Event) {
    if (event.exception?.values) {
      event.exception.values = event.exception.values.filter((exception) => {
        if (this.blacklist.includes(exception.value as string)) {
          //  eslint-disable-next-line no-console
          console.log('Sentry blacklisted:', exception)

          return false
        }

        return true
      })
    }

    return event
  },

  rateLimit(event: Sentry.Event) {
    if (event.exception?.values) {
      const exceptionValues: Sentry.Exception[] = []
      event.exception.values.forEach((exception) => {
        const key = `${exception.type}:${exception.value}`
        if (this.cache.has(key)) {
          //  eslint-disable-next-line no-console
          console.log('Sentry rate limited:', exception)
        } else {
          this.cache.put(key, '', dayjs().add(1, 'minute'))
          exceptionValues.push(exception)
        }
      })

      event.exception.values = exceptionValues
    }

    return event
  },
}

export default Sentry

import { isEmpty, set } from 'lodash-es'
import { Ref } from 'vue'

import { Library, Query, SortOrder } from '@/types'

import { Meta } from '..'
import { Books, Relations } from './index.d'

export type StandardBook = {
  LIBRARY: Library
  id: number
  localremoved?: boolean
}

export function debounceOption() {
  return {
    debounce: {
      fetch: 300,
    },
  }
}

export function usePaginationState({ pageSize }: { pageSize: number } = { pageSize: 10 }) {
  const meta = ref<Meta>({
    count: 0,
    num_pages: 0,
    page: 1,
    page_size: pageSize,
    paginate: true,
  })
  return { meta }
}

function addParentProperty(person, parent, visited = new Set()) {
  if (visited.has(person)) {
    return
  }

  visited.add(person)
  if (!('LIBRARY' in person)) return
  person.parent = parent

  for (const attr of Object.values(person)) {
    if (typeof attr === 'object' && attr !== null) {
      if (Array.isArray(attr) && attr.every((val) => typeof val === 'object' && val !== null)) {
        attr.forEach((child) => addParentProperty(child, person, visited))
      } else {
        addParentProperty(attr, person, visited)
      }
    }
  }
}

export function useSendRequest<TBook extends StandardBook>(library: Library, relations: Ref<Relations>, meta: Ref<Meta>, books: Ref<TBook[]>, sortBy: Ref<string>, sortOrder: Ref<SortOrder>) {
  async function sendRequest() {
    // let query
    // try {
    //   query = copyObject(relations.value.query)
    // } catch (error) {
    //   console.log(relations.value.query)
    // }
    // if (query) {
    //   query = cleanQuery(query)
    // }

    // let query: Query | undefined = copyObject(relations.value.query)
    // let query: Query | undefined = structuredClone(toRaw(relations.value.query))
    let query: Query | undefined = copyObject(relations.value.query)
    if (query) {
      query = cleanQuery(query)
    }

    const { data } = await api.post(`${library}/searches`, {
      page: meta.value.page,
      sort_by: sortBy.value,
      sort_order: sortOrder.value,
      relations: { ...relations.value, query },
      page_size: meta.value.page_size,
      paginate: meta.value.paginate,
    })
    // TODO(Rogier): Type this somehow.
    data.data.map((datum) => addParentProperty(datum))
    books.value = data.data
    meta.value = data.meta
  }

  return { sendRequest }
}

export function useUpdate<TBook extends StandardBook>({ library, book, books }: { library: Library; book?: Ref<TBook>; books?: Ref<TBook[]> }): { update: ({ id, updateBook }: { id: number; updateBook: Partial<TBook> }) => void } {
  // TODO: If query, only update existing fields according to query.
  function update({ id, updateBook }: { id: number; updateBook: Partial<TBook> }) {
    stores.loading[library] = true
    if (book && book.value) {
      book.value = { ...book.value, ...updateBook }
    } else if (books) {
      const index = books.value.findIndex((updateBook: TBook) => updateBook.id === id)
      if (index !== -1) {
        const original = books.value[index]
        if (original) {
          books.value[index] = { ...original, ...updateBook }
        }
      }
    }
    stores.loading[library] = false
  }
  return { update }
}

export function useRemove<TBook extends StandardBook>({
  library,
  book,
  books,
  update,
  meta,
  updateAvailable,
}: {
  library: Library
  book?: Ref<TBook[]>
  books?: Ref<TBook[]>
  update: ({ id, updateBook }: { id: number; updateBook: Partial<TBook> }) => void
  meta: Ref<Meta>
  updateAvailable: Ref<boolean>
}) {
  function remove({ id }: { id: number }) {
    stores.loading[library] = true
    if (books) {
      const index = books.value.findIndex((book: TBook) => book.id === id)
      if (index !== -1) {
        if (meta.value?.paginate && updateAvailable) {
          update({ id, updateBook: { localremoved: true } as Partial<TBook> })
          return
        }
        books.value.splice(index, 1)
      }
    } else if (book) {
      if (meta.value?.paginate && updateAvailable) {
        updateAvailable.value = true
        return
      }
      book = undefined
    }
    stores.loading[library] = false
  }

  return { remove }
}

export function useFetch<TBook extends StandardBook>(
  library: Library,
  meta: Ref<Meta>,
  relations: Ref<Relations>,
  books: Ref<TBook[]>,
  updateAvailable: Ref<boolean>,
  listenOnSocket: ReturnType<typeof useListenOnSocket>['listenOnSocket'],
  sortBy: Ref<string>,
  sortOrder: Ref<SortOrder>
) {
  const isListenOnSocketed = ref(false)

  async function fetch({ page, paginate, page_size: pageSize }: { page?: number; paginate?: boolean; page_size?: number } = {}) {
    const { sendRequest } = useSendRequest(library, relations, meta, books, sortBy, sortOrder)
    stores.loading[library] = true

    if (page) meta.value.page = page
    if (pageSize) meta.value.page_size = pageSize
    if (paginate !== undefined) meta.value.paginate = paginate

    /**
     * The data is retrieved and the meta information updated. If the page number is larger than the number of pages, set it to the last page and retrieve again.
     */
    await sendRequest()
    if (paginate) {
      if (meta.value.page > meta.value.num_pages) {
        meta.value.page = meta.value.num_pages
        await sendRequest()
      }
    }
    updateAvailable.value = false
    if (!isListenOnSocketed.value) {
      isListenOnSocketed.value = true
      listenOnSocket(relations, fetch)
    }
    stores.loading[library] = false
  }

  return { fetch }
}

// TODO extract to composable to have stored variables like id, action, meta (and depth?).
async function recursiveSocketHandler<TBook extends StandardBook>({
  relations,
  library,
  book,
  books,
  id,
  data,
  depth,
  action,
  updateAvailable,
  fetch,
  meta,
}: {
  relations: Ref<Relations> | Relations
  library: Library
  book: Ref<TBook>
  books: Ref<TBook[]>
  id: number
  data: TBook
  depth: number
  action: string
  addFound: Ref<boolean>
  updateAvailable: Ref<boolean>
  fetch: ({ page, paginate, page_size }?: { page?: number; paginate?: boolean; page_size?: number }) => void
  meta: Ref<Meta>
}) {
  depth++
  const innerRelations = unref(relations)
  for (const fieldName of Object.keys(innerRelations.children ?? [])) {
    const { addFound, add: childAdd } = useAdd({ meta, storeLibrary: library })

    const loopBooks = book ? [book.value] : books.value
    for (const parentBook of loopBooks) {
      if (addFound.value) return // We don't add anything anyway, and just fetch or show an update available.
      if (!parentBook) continue // It can be deleted during this process.
      // TODO: How up to date is this comment?
      // Must be ref because of useUpdate, which means reactivity is lost. Try to rewrite update to use/accept non-ref.
      // const childBooks = ref<Books[]| Books>(parentBook[fieldName])
      // if (!Array.isArray(childBooks.value)) childBooks.value = [childBooks.value]
      // let childBooks: Books[] = parentBook[fieldName]
      // if (!Array.isArray(childBooks)) childBooks = [childBooks]
      if (Array.isArray(parentBook[fieldName])) {
        const childBooks = ref<Books[]>(parentBook[fieldName])
        const { update: childUpdate } = useUpdate({ library, books: childBooks })
        const { remove: childRemove } = useRemove({ library, books: childBooks, update: childUpdate, meta, updateAvailable })
        if (stores.initialState.fieldMapping[fieldName] === library) {
          if (action === 'add') {
            await childAdd({
              id,
              books: childBooks,
              library,
              relations: innerRelations,
              childRelations: innerRelations.children?.[fieldName],
              updateAvailable,
              fetch,
              fieldName,
              parentLibrary: parentBook.LIBRARY,
              parentId: parentBook.id,
              data,
            })
          } else if (action === 'update') childUpdate({ id, updateBook: data })
          else if (action === 'remove') childRemove({ id })
        }
        parentBook[fieldName] = childBooks.value
        recursiveSocketHandler({ relations: innerRelations.children[fieldName], books: childBooks, data, id, library, depth, action, updateAvailable, fetch, childAdd, meta })
      } else {
        const childBook = ref<Books>(parentBook[fieldName])
        const { update: childUpdate } = useUpdate({ library, book: childBook })
        if (stores.initialState.fieldMapping[fieldName] === library) {
          if (action === 'add') {
            await childAdd({
              id,
              book: childBook,
              library,
              relations: innerRelations,
              childRelations: innerRelations.children[fieldName],
              updateAvailable,
              fetch,
              fieldName,
              parentLibrary: parentBook.LIBRARY,
              parentId: parentBook.id,
              data,
            })
          } else if (action === 'update') childUpdate({ id, updateBook: data })
        }
        parentBook[fieldName] = childBook.value
        recursiveSocketHandler({ relations: innerRelations.children[fieldName], book: childBook, data, id, library, depth, action, updateAvailable, fetch, childAdd, meta })
      }
    }
  }
}

function useAdd({ meta, storeLibrary }: { meta: Ref<Meta>; storeLibrary: Library }) {
  const addFound = ref(false)
  async function add<TBook extends StandardBook>({
    id,
    book,
    books,
    library,
    relations,
    childRelations,
    updateAvailable,
    fetch,
    fieldName,
    parentLibrary,
    parentId,
    data,
  }: {
    id: number
    book?: Ref<TBook>
    books?: Ref<TBook[]>
    library: Library
    relations: Relations
    childRelations: Relations
    updateAvailable?: Ref<boolean>
    fetch: ({ page, paginate, page_size }?: { page?: number; paginate?: boolean; page_size?: number }) => void
    fieldName?: string
    parentLibrary?: Library
    parentId?: number
    data: TBook
  }) {
    if (addFound.value) return
    // Create custom query to see if an object with this object exists, and whether that's our parent object.
    // Alternative method: Create a query for this object with a check for a parent field. Might not work for m2m.
    let queryValid = true
    if (relations?.query) {
      let searchQuery = { ...relations.query }
      if (fieldName) {
        const searchFieldName = `${fieldName.replace('_set', '')}__id__in`
        // Should we keep the whole query? We don't know the name though, and adding a new one is a Q OR statement.
        // let searchQuery = { ...relations.query }
        searchQuery = {
          updateBookCheck: {
            ...relations.query.full,
            [searchFieldName]: [id],
          },
        }
      }
      await api
        .post('validate-query', {
          forms: {
            // pk: searchQuery.updateBookCheck?.pk ?? id,
            pk: parentId ?? searchQuery.updateBookCheck?.pk ?? id,
            library: parentLibrary ?? storeLibrary,
            query: cleanQuery(searchQuery),
          },
        })
        .then((data) => {
          if (!data.data.valid) queryValid = false
        })
    }
    if (!queryValid) return

    if (meta.value?.paginate && updateAvailable && !childRelations.live) {
      addFound.value = true // from 2 to 0
      updateAvailable.value = true
      return
    } else if (childRelations.live) {
      const retrievedAppends = {}
      if (childRelations.appends?.length) {
        const { data: appendsData } = await api.post(`${library}/searches/${id}`, { relations: { fields: [], appends: childRelations.appends } })
        Object.assign(retrievedAppends, appendsData)
      }
      if (book && !book.value) {
        book.value = { ...data, ...retrievedAppends }
      } else if (books && Array.isArray(books.value)) {
        books.value.push({ ...data, ...retrievedAppends })
      }
    } else {
      await fetch()
    }
  }
  return { addFound, add }
}

export function useListenOnSocket<TBook extends StandardBook>(
  storeLibrary: Library,
  books: Ref<TBook[]>,
  update: ({ id, updateBook }: { id: number; updateBook: Partial<TBook> }) => void,
  meta: Ref<Meta>,
  sortBy: Ref<string>,
  sortOrder: Ref<SortOrder>,
  remove?: ({ id }: { id: number }) => void,
  updateAvailable?: Ref<boolean>
) {
  function listenOnSocket(relations: Ref<Relations>, fetch: ({ page, paginate, page_size }?: { page?: number; paginate?: boolean; page_size?: number }) => void) {
    const { add } = useAdd({ meta, storeLibrary })

    // TODO: data is not always TBook any more.
    socket.organisation.on('update_store', async ({ action, library, data, id }: { action: string; library: Library; data: TBook; id: number }) => {
      const depth = 0

      // TODO: Maybe check full relations converted to libraries first to check if recursion is even needed?

      await recursiveSocketHandler({ relations, books, data, id, library, depth, action, fetch, updateAvailable, meta })

      if (library !== storeLibrary) return

      switch (action) {
        case 'add':
          add({ id, library, relations: relations.value, childRelations: relations.value, updateAvailable, fetch, books, data })
          if (meta.value?.paginate && updateAvailable) {
            updateAvailable.value = true
            return
          }
          await fetch?.()
          break
        case 'update':
          update({ id, updateBook: data })
          break
        case 'remove':
          if (remove) {
            remove({ id })
            break
          }
          if (meta.value?.paginate) {
            update({ id, updateBook: { localremoved: true } as Partial<TBook> })
            if (updateAvailable) updateAvailable.value = true
            break
          }
          await delay(1000)
          await fetch?.({})
          break
      }
    })
  }
  return { listenOnSocket }
}

function cleanQuery(query: Query): Query {
  for (const [key, statement] of Object.entries(query)) {
    query[key] = Object.keys(statement).reduce<Record<string, unknown>>((a, k) => {
      const value = statement?.[k]
      if (!value && typeof value !== 'number' && typeof value !== 'boolean') return a
      // TODO: Rework filtering of queries and/or setting of queries so that empty lists actually are used to filter, and no special exception for pks.
      if (k !== 'pk__in' && typeof value === 'object' && isEmpty(value)) return a

      a[k] = value

      return a
    }, {})
  }
  return query
}

export function useDefaultRelations<TBook extends StandardBook>(
  library: Library,
  meta: Ref<Meta>,
  books: Ref<TBook[]>,
  updateAvailable: Ref<boolean>,
  listenOnSocket: ReturnType<typeof useListenOnSocket>['listenOnSocket'],
  sortBy: Ref<string>,
  sortOrder: Ref<SortOrder>,
  relations: Ref<Relations>
) {
  const resetRelations = copyObject(relations.value)
  const { fetch } = useFetch(library, meta, relations, books, updateAvailable, listenOnSocket, sortBy, sortOrder)

  async function resetQuery(forcedFilters: undefined | object) {
    const newRelations = copyObject(resetRelations)

    if (!isEmpty(forcedFilters)) Object.assign(newRelations.query, { default: forcedFilters })

    relations.value = newRelations
    await fetch({})
  }

  function updateQuery(path: string, query: unknown) {
    set(relations.value, path, query)
  }

  return { relations, resetRelations, resetQuery, updateQuery, fetch }
}

export function useStore<TBook extends StandardBook>(library: Library, relations: Ref<Relations>, books: Ref<TBook[]>, updateAvailable: Ref<boolean>, pageSize = 10, _sortBy = 'last_updated', _sortOrder: SortOrder = 'desc') {
  const sortOrder = ref<SortOrder>(_sortOrder)
  const sortBy = ref<string>(_sortBy)

  const { update } = useUpdate({ library, books })
  const { meta } = usePaginationState({ pageSize })
  const { remove } = useRemove({ library, books, update, meta, updateAvailable })
  const { listenOnSocket } = useListenOnSocket<TBook>(library, books, update, meta, sortBy, sortOrder, remove as Ref<StandardBook[]>, updateAvailable)
  const { resetRelations, resetQuery, updateQuery, fetch } = useDefaultRelations(library, meta, books, updateAvailable, listenOnSocket, sortBy, sortOrder, relations)
  const { sendRequest } = useSendRequest(library, relations, meta, books, sortBy, sortOrder)
  // const { fetch } = useFetch(library, meta, relations, books, updateAvailable, listenOnSocket, sortBy, sortOrder)

  async function updateSort({ prop, order }: { prop: string; order: SortOrder }) {
    sortBy.value = prop
    sortOrder.value = order
    await fetch()
  }

  return { fetch, listenOnSocket, meta, relations, remove, resetRelations, resetQuery, sortOrder, sortBy, sendRequest, update, updateQuery, updateSort }
}

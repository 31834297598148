<template>
  <DialogNotifications v-model:visible="visible.notifications" />
</template>

<script setup lang="ts">
const visible = ref({
  notifications: false,
})

onBeforeMount(() => {
  bus.on('dialog.show', (name) => {
    visible.value[name] = true
  })

  bus.on('dialog.hide', (name) => {
    visible.value[name] = false
  })

  bus.on('dialog.toggle', (name) => {
    visible.value[name] = !visible.value[name]
  })
})

onBeforeUnmount(() => {
  bus.off('dialog.show')
  bus.off('dialog.hide')
  bus.off('dialog.toggle')
})
</script>

import { defineStore, StoreDefinition } from 'pinia'

import { Library } from '@/types'

import { Relation, StateBook, StoreRelation } from '.'
import { debounceOption, StandardBook, useStore } from './composables'
import { DataVersion, DataVersionRelation } from './dataversions'
import { Experiment, ExperimentRelation } from './experiments'
import { Book } from './index.d'
import { Job, JobRelation } from './jobs'
import { Model, ModelRelation } from './models'
import { Organisation, OrganisationRelation } from './organisation'
import { Project, ProjectRelation } from './projects'
import { Status } from './statuses'
import { ModelType } from './types'
import { User, UserRelation } from './users'

interface ExperimentGroupChildren {
  firenze_organisation: OrganisationRelation
  firenze_user: UserRelation
  firenze_project: ProjectRelation
  firenze_training_data_version: DataVersionRelation
  firenze_teacher_model: ModelRelation
  firenze_trained_on_teacher_model: ModelRelation
  firenze_cleaned_data_version: DataVersionRelation
  firenze_augmented_data_version: DataVersionRelation
  firenze_experiment_group_teacher: ExperimentGroupRelation
  firenzeexperiment_set: ExperimentRelation
  firenzejob_set: JobRelation
}

export type ExperimentGroupRelation = Relation<ExperimentGroup, Appends, ExperimentGroupChildren>

export interface ExperimentGroupsQuery {
  pk: number
}

interface Appends {
  status: ExperimentGroupStatus
}

const defaultQuery = {
  default: {},
}

interface ExperimentGroupStatus {
  orchestration: Status
  data: Status
  eda: Status
  training: Status
  inference: Status
  evaluation: Status
}

export interface ExperimentGroup extends Book {
  LIBRARY: 'experimentgroups'
  augment_options: object
  balance_classes: boolean
  cleaner_options: object
  description: string
  entry_name: string
  predict_column: Record<string, 'str' | 'float' | 'int'>
  train_columns: Record<string, 'str' | 'float' | 'int'>
  firenze_augmented_data_version_id: number
  firenze_augmented_data_version: DataVersion
  firenze_cleaned_data_version_id: number
  firenze_cleaned_data_version: DataVersion
  firenze_experiment_group_teacher_id: number
  firenze_experiment_group_teacher: ExperimentGroup
  firenze_organisation: Organisation
  firenze_project: Project
  firenze_project_id: number
  firenze_teacher_model_id: number
  firenze_teacher_model: Model
  firenze_trained_on_teacher_model_id: number
  firenze_trained_on_teacher_model: Model
  firenze_training_data_version_id: number
  firenze_training_data_version: DataVersion
  firenzeexperiment_set: Experiment[]
  firenzejob_set: Job[]
  firenze_user: User
  hyperopt_options: object
  experiment_group_type: keyof typeof stores.jsons.renames.experiment_group_types
  max_evals: number
  model_type: ModelType
  singular: boolean
}
export function createExperimentGroupsStore<T extends StoreRelation<T, ExperimentGroupRelation, ExperimentGroup, ExperimentGroupChildren, Appends>>(id: string, initialRelations: T) {
  const library = 'experimentgroups'
  type StoreExperimentGroup = StateBook<T['fields'], T['appends'], T['hidden_fields'], T['children'], ExperimentGroup, Appends, ExperimentGroupChildren, typeof library>

  return defineStore(
    id,
    () => {
      const experimentgroups = ref<StoreExperimentGroup[]>([])
      const updateAvailable = ref(false)

      const relations = ref<ExperimentGroupRelation>({ ...initialRelations, query: defaultQuery })
      const { fetch, listenOnSocket, meta, relations: _relations, remove, resetQuery, sendRequest, update, updateQuery, updateSort } = useStore(library, relations, experimentgroups as Ref<StandardBook[]>, updateAvailable)

      return { experimentgroups, fetch, listenOnSocket, meta, relations, remove, resetQuery, sendRequest, update, updateAvailable, updateQuery, updateSort }
    },
    { ...debounceOption() }
  )
}

export const useExperimentGroupsStore = createExperimentGroupsStore('experimentgroups', {})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useExperimentGroupsStore as unknown as StoreDefinition, import.meta.hot))
}

import { StoreDefinition } from 'pinia'

import { Relation, StateBook, StoreRelation } from '.'
import { debounceOption, StandardBook, useStore } from './composables'
import { DataVersion, DataVersionRelation } from './dataversions'
import { Book } from './index.d'
import { OrganisationRelation } from './organisation'
import { Portfolio, PortfolioRelation } from './portfolios'
import { UserRelation } from './users'

interface DatumChildren {
  firenze_latest_data_version: DataVersionRelation
  firenze_organisation: OrganisationRelation
  firenze_portfolios: PortfolioRelation
  firenze_user: UserRelation
  firenzedataversion_set: DataVersionRelation
}

export type DatumRelation = Relation<Datum, Appends, DatumChildren>

export interface DataQuery {
  archived: boolean
  entry_name__icontains: string
  firenze_user_ids__in: number[]
  languages__contains: string[]
  licenses__contains: string[]
  parsed_format__in: string[]
  pk: number
  show_in_library: boolean
  technique__in: string[]
}

interface Appends {
  object_usage: Record<string, number>
}

const defaultQuery = {
  default: {
    archived: false,
    show_in_library: true,
    technique__in: [],
  },
}

export interface Datum extends Book {
  LIBRARY: 'data'
  archived: boolean
  description: string
  documentation: string
  entry_name: string
  firenze_latest_data_version_id: number
  firenze_latest_data_version: DataVersion
  firenze_portfolios_ids: number[]
  firenze_portfolios: Portfolio[]
  firenzedataversion_set: DataVersion[]
  languages: (keyof typeof stores.jsons.renames.languages)[]
  licenses: (keyof typeof stores.jsons.renames.licenses)[]
  lifecycle: keyof typeof stores.jsons.renames.lifecycles
  localremoved: boolean
  parsed_format: keyof typeof stores.jsons.renames.parsed_formats
  show_in_library: boolean
  technique: keyof typeof stores.jsons.renames.techniques
  object_usage: Record<string, number>
}

export function createDataStore<T extends StoreRelation<T, DatumRelation, Datum, DatumChildren, Appends>>(id: string, initialRelations: T) {
  const library = 'data'
  type StoreDatum = StateBook<T['fields'], T['appends'], T['hidden_fields'], T['children'], Datum, Appends, DatumChildren, typeof library>

  return defineStore(
    id,
    () => {
      const data = ref<StoreDatum[]>([])
      const updateAvailable = ref(false)

      const relations = ref<DatumRelation>({ ...initialRelations, query: defaultQuery })
      const { fetch, listenOnSocket, meta, relations: _relations, remove, resetQuery, sendRequest, update, updateQuery, updateSort } = useStore(library, relations, data as Ref<StandardBook[]>, updateAvailable)

      return { data, fetch, listenOnSocket, meta, relations, remove, resetQuery, sendRequest, update, updateAvailable, updateQuery, updateSort }
    },
    { ...debounceOption() }
  )
}

export const useDataStore = createDataStore('data', {
  appends: ['object_usage'],
  children: {
    firenze_latest_data_version: {
      fields: ['entry_name', 'date_of_creation', 'last_updated', 'version'],
      children: {
        firenze_user: {
          appends: ['full_name'],
        },
        firenze_datum: {},
      },
    },
    firenze_user: {
      appends: ['full_name'],
    },
    firenzedataversion_set: {
      appends: ['status', 'object_usage'],
      fields: ['entry_name', 'date_of_creation', 'last_updated', 'version', 'train_columns', 'predict_column'],
      children: {
        firenze_user: {
          appends: ['full_name'],
        },
        firenze_datum: {},
      },
    },
  },
})

export function createDataRelations<T extends StoreRelation<T, DatumRelation, Datum, DatumChildren, Appends>>(relations: T) {
  return relations
}

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useDataStore as unknown as StoreDefinition, import.meta.hot))
}

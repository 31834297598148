import { StoreDefinition } from 'pinia'

import { Relation, StateBook, StoreRelation } from '.'
import { debounceOption, StandardBook, useStore } from './composables'
import { Book } from './index.d'
import { Model, ModelRelation } from './models'
import { OrganisationRelation } from './organisation'
import { Resolution } from './types'

interface ModelsControlChildren {
  firenze_organisation: OrganisationRelation
  firenze_model: ModelRelation
}

export type ModelsControlRelation = Relation<ModelsControl, Appends, ModelsControlChildren>

export interface ModelsControlsQuery {
  pk: number
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface Appends {}

const defaultQuery = {
  default: {},
}

export interface ModelsControl extends Book {
  LIBRARY: 'modelscontrols'
  firenze_model_id: number
  firenze_model: Model
  verified_license_description: string
  verified_license_status: Resolution
  verified_model_bias_description: string
  verified_model_bias_status: Resolution
  verified_model_outcomes_description: string
  verified_model_outcomes_status: Resolution
}

export function createModelsControlsStore<T extends StoreRelation<T, ModelsControlRelation, ModelsControl, ModelsControlChildren, Appends>>(id: string, initialRelations: T) {
  const library = 'modelscontrols'
  type StoreModelsControl = StateBook<T['fields'], T['appends'], T['hidden_fields'], T['children'], ModelsControl, Appends, ModelsControlChildren, typeof library>

  return defineStore(
    id,
    () => {
      const modelscontrols = ref<StoreModelsControl[]>([])
      const updateAvailable = ref(false)

      const relations = ref<ModelsControlRelation>({ ...initialRelations, query: defaultQuery })
      const { fetch, listenOnSocket, meta, relations: _relations, remove, resetQuery, sendRequest, update, updateQuery, updateSort } = useStore(library, relations, modelscontrols as Ref<StandardBook[]>, updateAvailable)

      return { modelscontrols, fetch, listenOnSocket, meta, relations, remove, resetQuery, sendRequest, update, updateAvailable, updateQuery, updateSort }
    },
    { ...debounceOption() }
  )
}

export const useModelsControlsStore = createModelsControlsStore('modelscontrols', {})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useModelsControlsStore as unknown as StoreDefinition, import.meta.hot))
}

<template>
  <div v-if="$user.id !== 0" class="avatar-container">
    <img v-if="reallyUseHat && hat === 'xmas'" class="xmas" :height="size" src="/images/hats/xmas.png" :width="size" />
    <img v-if="reallyUseHat && hat === 'rainbow'" class="rainbow" :height="size" src="/images/hats/rainbow.png" :width="size * 2" />
    <VAvatar v-if="placeholder" :placeholder="placeholder" :seed="userId" :size="size" :src="src" />
  </div>
</template>

<script setup lang="ts">
import { baseURL } from '@/bootstrap'

interface Props {
  useHat?: boolean
  size?: number
  userId: number
  hideSrc?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  size: 26,
})

const placeholder = computed(() => (user.value ? `${user.value.first_name.charAt(0)}${user.value.last_name.charAt(0)}`.toUpperCase() : null))

const reallyUseHat = computed(() => props.useHat && ['local', 'experimental', 'testing'].includes(env.app_env ?? ''))
const user = computed(() => (props.userId === $user.id ? $user : Object.values(stores.users.users).find((user) => user.id === props.userId)))
const src = computed(() => (props.hideSrc ? '' : version.value ? `${baseURL}/api/avatars/${props.userId}?v=${version.value}` : ''))
const version = computed(() => stores.avatars.avatars[props.userId]?.version)

/**
 * Determine whether and which hat to show.
 */
const hat = computed(() => {
  const now = dayjs()
  if (now.month() === 11) return 'xmas' // If December: Christmas.
  if (now.month() === 5) return 'rainbow' // If June: Pride month.
  return null
})

onBeforeMount(() => {
  stores.avatars.fetch()
})
</script>

<style lang="scss" scoped>
.xmas {
  position: absolute;
  margin-left: -14px;
  margin-top: -16px;
  transform: rotate(-20deg);
}
.rainbow {
  position: absolute;
  margin-top: -11px;
  margin-left: -10.5px;
  height: 80%;
  width: 165%;
  max-width: 200%;
}
</style>

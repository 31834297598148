import { StoreDefinition } from 'pinia'

import { Optional } from '@/types'

import { Relation, StateBook, StoreRelation } from '.'
import { debounceOption, StandardBook, useStore } from './composables'
import { DataVersion, DataVersionRelation } from './dataversions'
import { Experiment, ExperimentRelation } from './experiments'
import { Book } from './index.d'
import { OrganisationRelation } from './organisation'
import { Portfolio, PortfolioRelation } from './portfolios'
import { Status } from './statuses'
import { User, UserRelation } from './users'

interface ModelChildren {
  firenze_evaluation_data_versions: DataVersionRelation
  firenze_experiment: ExperimentRelation
  firenze_organisation: OrganisationRelation
  firenze_portfolios: PortfolioRelation
  firenze_trainers: UserRelation
  firenze_training_data_versions: DataVersionRelation
  firenze_user: UserRelation
}

export type ModelRelation = Relation<Model, Appends, ModelChildren>

export interface ModelsQuery {
  pk: number
  archived: boolean
  entry_name__icontains: string
  environment__in: ('development' | 'inactive' | 'production_ready' | 'in_production' | 'experimentation')[]
  firenze_experiment__firenze_project_id: number
  firenze_user_ids__in: number[]
  languages__contains: string[]
  licenses__contains: string[]
  teacher: boolean
  technique__in: string[]
}

interface Appends {
  status: ModelStatus
  downloads: number
  items_inferred: number
  overall_model_word_contributions: Record<string, number>
  main_precision: number | null
  main_recall: number | null
  main_f1_score: number | null
  main_r2_score: number | null
  main_mape_score: number | null
  main_mcc_score: number | null
  main_support: number | null
  controls_resolved: string
  file_size: number
  full_firenze_image_version: FirenzeImageVersion
}

const defaultQuery = {
  default: {
    archived: false,
    environment__in: ['development', 'production_ready', 'in_production'],
    firenze_experiment__firenze_experiment_group__experiment_group_type__in: ['single', 'student'],
  },
}

interface ModelStatus {
  explanation: Status
  inference: Status
  orchestration: Status
}

export interface ClassificationReportTableGeneral {
  name: 'accuracy' | 'macro avg' | 'weighted avg' | 'mcc' | 'mae' | 'mse' | 'r2' | 'rmse'
  f1_score: number
  precision: number
  recall: number
  support: number
}

export interface ClassificationReportTableLabel {
  name: string
  f1_score: number
  precision: number
  recall: number
  support: number
}

type ClassificationReportTable = Record<
  string,
  {
    general: ClassificationReportTableGeneral[]
    labels: ClassificationReportTableLabel[]
  }
>

interface EvaluationStats {
  average_speed_per_text: number
  best_threshold: number
  confusion_matrix_plot: string
  residual_plot: string
  actual_predicted_plot: string
  data_name: string
  classification_report_table: ClassificationReportTable
  confusion_matrix_table: object
}

interface TrainingStats {
  accuracy: number
  loss: number
  val_accuracy: number
  val_loss: number
}

interface ModelStatsData {
  distribution_plot: Record<string, number>
}

interface ModelStats {
  training: Record<string, TrainingStats>
  evaluation: Record<string, EvaluationStats>
  data: ModelStatsData
}

interface ModelConfigs {
  evaluation?: object
  training: {
    algorithm: string
    base_estimator: string
    learning_rate: number
    n_estimators: number
    random_state: number
  }
}

// export type ImageVersion = 'ktrain' | 'longformer' | 'luke' | 'data_parsing'

export interface FirenzeImageVersion {
  date_of_creation: string
  id: number
  IMAGE_NAME: string
  image_version: string
  is_latest_stable_major: boolean
  is_latest_stable_minor: boolean
  is_latest_stable_patch: boolean
  last_updated: string
  latest_major_version: number
  latest_patch_version: number
  latest_pre_release_minor_version: number
  latest_pre_release_version: string
  latest_stable_minor_version: number
  latest_stable_version: string
  LIBRARY: 'imageversions'
  major: number
  minor: number
  patch: number
  pre_release: string | null
}

export interface Model extends Book {
  LIBRARY: 'models'
  archived: boolean
  configs: ModelConfigs
  description: string
  documentation: string
  entry_name: string
  environment: keyof typeof stores.jsons.renames.environments
  file_name: string
  predict_column: Record<string, 'str' | 'int' | 'float'>
  train_columns: Record<string, 'str' | 'int' | 'float'>
  firenze_evaluation_data_versions_ids: number[]
  firenze_evaluation_data_versions: DataVersion[]
  firenze_experiment_id: number
  firenze_experiment: Experiment
  firenze_model_storage_object_id: number
  firenze_model_storage_object: number
  firenze_portfolios_ids: number[]
  firenze_portfolios: Portfolio[]
  firenze_trainers_ids: number[]
  firenze_trainers: User[]
  firenze_training_data_versions_ids: number[]
  firenze_training_data_versions: DataVersion[]
  inference_history: object[]
  languages: (keyof typeof stores.jsons.renames.languages)[]
  licenses: (keyof typeof stores.jsons.renames.licenses)[]
  model_speed: number
  parsed_format: keyof typeof stores.jsons.renames.parsed_formats
  requirements: string
  stats: ModelStats
  supported_labels: string[]
  technique: keyof typeof stores.jsons.renames.techniques
  trained_by_algorithm: keyof typeof stores.jsons.renames.model_types
  trained_in_firenze: boolean
  training_time: Optional<number>
}

export function createModelsStore<T extends StoreRelation<T, ModelRelation, Model, ModelChildren, Appends>>(id: string, initialRelations: T) {
  const library = 'models'
  type StoreModel = StateBook<T['fields'], T['appends'], T['hidden_fields'], T['children'], Model, Appends, ModelChildren, typeof library>

  return defineStore(
    id,
    () => {
      const models = ref<StoreModel[]>([])
      const updateAvailable = ref(false)

      const relations = ref<ModelRelation>({ ...initialRelations, query: defaultQuery })
      const { fetch, listenOnSocket, meta, relations: _relations, remove, resetQuery, sendRequest, update, updateQuery, updateSort } = useStore(library, relations, models as Ref<StandardBook[]>, updateAvailable)

      return { models, fetch, listenOnSocket, meta, relations, remove, resetQuery, sendRequest, update, updateAvailable, updateQuery, updateSort }
    },
    { ...debounceOption() }
  )
}

export const useModelsStore = createModelsStore('models', {
  appends: ['controls_resolved'],
  children: {
    firenze_user: {
      fields: ['first_name', 'last_name'],
      appends: ['full_name'],
    },
    firenze_experiment: {
      fields: [],
      appends: [],
      children: {
        firenze_experiment_group: {
          // appends: ['status'],
          fields: ['experiment_group_type', 'singular'],
        },
      },
    },
  },
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useModelsStore as unknown as StoreDefinition, import.meta.hot))
}

<template>
  <DialogForm :form="form" :loading="loading" placement="left" :rules="rules" submit-text="Submit" title="Malfunction report" :visible="visible" @submit="submit" @update:visible="hide">
    <p style="margin-bottom: 15px">Use this form to report problems</p>

    <VTextarea v-model="form.description" label="Description" :maxlength="2000" prop="description" required :rows="4" />

    <VTextarea v-model="form.reproducible_steps" label="Reproducible steps" :maxlength="2000" prop="reproducible_steps" required :rows="4" />

    <VSelect v-model="form.category" filterable label="Category" reequired @update:model-value="categoryChangedByUser = true">
      <VOption v-for="label in Object.keys(categories)" :key="label" :label="label" :value="label" />
    </VSelect>

    <VSelect v-model="form.follow_up_email" label="Can we follow-up on this feature request?">
      <VOption v-for="(item, index) in options" :key="index" :label="item.label" :value="item.value" />
    </VSelect>

    <VUpload accept=".jpg,.jpeg,.png,.mp4,.webm,.pdf,.csv,.txt,.json" drag :files="form.attachment" :max="5" multiple :pastable="visible" style="display: inline" @update:files="updateAttachment" />
  </DialogForm>
</template>

<script setup lang="ts">
export interface DialogMalfunctionReportForm {
  attachment: File[]
  category: string
  description: string
  follow_up_email: boolean
  reproducible_steps: string
}

const defaultForm = (): DialogMalfunctionReportForm => ({
  attachment: [] as File[],
  category: '',
  description: '',
  follow_up_email: true,
  reproducible_steps: '',
})

const emit = defineEmits<{
  (event: 'update:visible', value: boolean): void
}>()

interface Props {
  visible: boolean
}

const props = defineProps<Props>()

const { categories, categoryChangedByUser, form, hide, loading, options, rules, setDefaultForm, updateAttachment } = useSupportReport(defaultForm, 'malfunctionreports', props, emit)

async function submit() {
  const formData = new FormData()

  for (const [key, value] of Object.entries(form)) {
    if (key !== 'attachment') formData.append(key, String(value))
  }

  form.attachment.forEach((file, index) => formData.append(`files[${index}]`, file))
  formData.append('title', ((form.description.split('\n')?.[0] ?? '').split('. ')?.[0] ?? '').slice(0, 180))
  formData.append('url', document.location.href)
  formData.append('viewport_width', String(window.innerWidth))
  formData.append('viewport_height', String(window.innerHeight))

  loading.value = true
  categoryChangedByUser.value = false
  setDefaultForm()
  await api.post('malfunction-report', formData)
}
</script>

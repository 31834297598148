import './bootstrap'
import 'md-editor-v3/lib/style.css'

import { PiniaDebounce } from '@pinia/plugin-debounce'
import { VartionUI } from '@vartion/ui'
import { BarChart, GaugeChart, HeatmapChart, LineChart, PieChart } from 'echarts/charts'
import { DataZoomComponent, GridComponent, LegendComponent, LegendScrollComponent, PolarComponent, SingleAxisComponent, TitleComponent, ToolboxComponent, TooltipComponent, VisualMapComponent } from 'echarts/components'
import { use } from 'echarts/core'
import { CanvasRenderer } from 'echarts/renderers'
import debounce from 'lodash-es/debounce'
import MdEditor from 'md-editor-v3'
import ECharts from 'vue-echarts'

import App from './App.vue'
import { can } from './helpers'
import router from './router'
import { bus } from './services/bus'
import { stores } from './stores'
import { GlobalOrganisation } from './stores/organisation'

use([
  BarChart,
  CanvasRenderer,
  DataZoomComponent,
  GaugeChart,
  GridComponent,
  HeatmapChart,
  LegendComponent,
  LegendScrollComponent,
  LineChart,
  PieChart,
  PolarComponent,
  SingleAxisComponent,
  TitleComponent,
  ToolboxComponent,
  TooltipComponent,
  VisualMapComponent,
])

const app = createApp({ ...App, name: 'Firenze' })

window.bus = bus
window.router = router
window.app = app

app.component('EChart', ECharts)
VartionUI.install(app, {
  router,
  config: {
    VForm: { validateOn: 'keyup', debounce: 500 },
  },
})

export default function asyncDebounce(func: (...args: unknown[]) => void, wait: number) {
  const debounced = debounce(
    async (resolve, reject, bindSelf, args) => {
      try {
        const result = await func.bind(bindSelf)(...args)
        resolve(result)
      } catch (error) {
        reject(error)
      }
    },
    wait,
    { leading: true, trailing: true }
  )

  // This is the function that will be bound by the caller, so it must contain the `function` keyword.
  function returnFunc(this: unknown, ...args: unknown[]) {
    return new Promise((resolve, reject) => {
      debounced(resolve, reject, this, args)
    })
  }

  return returnFunc
}

const pinia = createPinia()
pinia.use(PiniaDebounce(asyncDebounce))
declare module '@pinia/plugin-debounce' {
  export interface Config {
    Debounce: typeof debounce
  }
}

app.use(pinia)
app.use(router)
app.use(stores)
app.use(MdEditor)

app.config.globalProperties.$bus = bus
app.config.globalProperties.$can = can
app.config.globalProperties.$env = window.env = window.stores.initialState.env
app.config.globalProperties.$user = window.$user = window.stores.user.user
app.config.globalProperties.$organisation = window.$organisation = window.stores.organisation.organisation as GlobalOrganisation
app.config.globalProperties.$window = window
app.config.globalProperties.dayjs = dayjs

app.mount('#app')

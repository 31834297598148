import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'

import { Permission } from '@/types'

import { useUpdateChecker } from './services/updateChecker'

const updateChecker = useUpdateChecker()
export function onImportError(error: Error) {
  updateChecker.check()

  throw error
}

declare module 'vue-router' {
  interface RouteMeta {
    guest?: boolean
    permissions?: Permission[]
    title: string
    login?: boolean
  }
}

const AnnotationProjectHistory = () => import('./components/AnnotationProjectHistory.vue')
const AnnotationProjectInformation = () => import('./components/AnnotationProjectInformation.vue')
const AnnotationProjectLabels = () => import('./components/AnnotationProjectLabels.vue')
const AnnotationProjectUsage = () => import('./components/AnnotationProjectUsage.vue')
const BookDocumentation = () => import('./components/BookDocumentation.vue')
const ViewLibrary = () => import('./views/ViewLibrary.vue')
const Login = () => import('./components/auth/Login.vue')
const Reset = () => import('./components/auth/Reset.vue')
const ResetEmail = () => import('./components/auth/ResetEmail.vue')
const PortfolioInformation = () => import('./components/PortfolioInformation.vue')
const PortfolioProjects = () => import('./components/PortfolioProjects.vue')
const PortfolioData = () => import('./components/PortfolioData.vue')
const PortfolioModels = () => import('./components/PortfolioModels.vue')
const PortfolioJobs = () => import('./components/PortfolioJobs.vue')
const PortfolioControls = () => import('./components/PortfolioControls.vue')
const PortfolioAnnotationProjects = () => import('./components/PortfolioAnnotationProjects.vue')
const ProjectAssociatedDataAndModels = () => import('./components/ProjectAssociatedDataAndModels.vue')
const ProjectTrainedModels = () => import('./components/ProjectTrainedModels.vue')
const ProjectExperiments = () => import('./components/ProjectExperiments.vue')
const ProjectInformation = () => import('./components/ProjectInformation.vue')
const ProjectUsage = () => import('./components/ProjectUsage.vue')
const ProjectTrainingStatistics = () => import('./components/ProjectTrainingStatistics.vue')
const SettingsAccount = () => import('./components/SettingsAccount.vue')
const SettingsOrganisation = () => import('./components/SettingsOrganisation.vue')
const SettingsIntegration = () => import('./components/SettingsIntegration.vue')
const SettingsConfiguration = () => import('./components/SettingsConfiguration.vue')
const SettingsUsers = () => import('./components/SettingsUsers.vue')
const StatisticsJobs = () => import('./components/StatisticsJobs.vue')
const StatisticsReports = () => import('./components/StatisticsReports.vue')
const ViewAnnotationProject = () => import('./views/ViewAnnotationProject.vue')
const ViewAdministration = () => import('./views/ViewAdministration.vue')
const ViewHome = () => import('./views/ViewHome.vue')
const ViewGettingStarted = () => import('./views/ViewGettingStarted.vue')
const ViewPortfolio = () => import('./views/ViewPortfolio.vue')
const ViewProject = () => import('./views/ViewProject.vue')
const ViewResponse = () => import('./views/ViewResponse.vue')
const ViewSettings = () => import('./views/ViewSettings.vue')
const ViewStatistics = () => import('./views/ViewStatistics.vue')
const ViewModel = () => import('./views/ViewModel.vue')
const ViewDatum = () => import('./views/ViewDatum.vue')
const ViewDataVersion = () => import('./views/ViewDataVersion.vue')

/*
routes sorting:
Paths without any variables come first, with variables after.
Variable-less paths should be sorted alphabetically
Paths with variables should be sorted on, in order:
1. Descending on first occurrence of a var segment (so paths with the first argument being var being at the bottom)
2. Repeat 1. from one segment level deeper
3. amount of segments
e.g.
1. /single
2. /two/segments
3. /very/late/var/:foo
4. /foo/:bar/baz/qux
5 . /foo/:bar/:baz/:qux
6 . /foo/:bar/:baz/
7. /:early/var/foo/bar/baz
*/
const routes: RouteRecordRaw[] = [
  // No variables.
  {
    path: '/',
    meta: { title: 'Home', permissions: ['ViewObjects'] },
    name: 'home',
    component: ViewHome,
  },
  { path: '/getting-started', component: ViewGettingStarted, meta: { title: 'Getting started', permissions: ['ViewObjects'] }, name: 'getting-started' },
  { path: '/login', component: Login, meta: { title: 'Sign in', guest: true, permissions: [] }, name: 'login' },
  { path: '/password/reset', component: ResetEmail, meta: { title: 'Reset password', guest: true, permissions: [] }, name: 'password-reset-request' },
  { path: '/password/reset/:token', component: Reset, meta: { title: 'Reset password', guest: true, permissions: [] }, name: 'password-reset' },
  { path: '/administration', component: ViewAdministration, meta: { title: 'Administration', permissions: [] }, name: 'administration' },
  {
    path: '/settings',
    meta: { title: 'Settings', permissions: [] },
    name: 'settings',
    component: ViewSettings,
    children: [
      { path: '', component: SettingsAccount, meta: { title: 'Account' }, name: 'settings.account' },
      { path: 'account', component: SettingsAccount, meta: { title: 'Account' } },
      { path: 'organisation', component: SettingsOrganisation, meta: { title: 'Organisation', permissions: ['ViewObjects'] } },
      { path: 'users', component: SettingsUsers, meta: { title: 'Users', permissions: ['ViewObjects'] } },
      { path: 'integration', component: SettingsIntegration, meta: { title: 'Integration', permissions: ['ViewObjects'] } },
      { path: 'configuration', component: SettingsConfiguration, meta: { title: 'Configuration' } },
    ],
  },
  {
    path: '/statistics',
    name: 'statistics',
    meta: { title: 'Statistics', permissions: ['ViewObjects'] },
    component: ViewStatistics,
    children: [
      { path: '', component: StatisticsJobs, meta: { title: 'Jobs', permissions: ['ViewObjects'] }, name: 'statistics.jobs' },
      { path: 'jobs', component: StatisticsJobs, meta: { title: 'Jobs', permissions: ['ViewObjects'] } },
      { path: 'reports', component: StatisticsReports, meta: { title: 'Reports', permissions: ['ViewObjects'] } },
    ],
  },
  // Variables.
  {
    path: '/projects/:id',
    name: 'projects.id',
    meta: { title: 'Project', permissions: ['ViewObjects'] },
    component: ViewProject,
    children: [
      { path: '', redirect: { name: 'project.id.experiment' }, name: 'project.id.experiments' },
      {
        path: 'experiments',
        name: 'project.id.experiment',
        component: ProjectExperiments,
        meta: { title: 'Experiments', permissions: ['ViewObjects'] },
      },
      {
        path: 'associated-data-and-models',
        component: ProjectAssociatedDataAndModels,
        meta: { title: 'Associated Data and Models', permissions: ['ViewObjects'] },
      },
      { path: 'results', component: ProjectTrainingStatistics, meta: { title: 'Results', permissions: ['ViewObjects'] } },
      { path: 'information', component: ProjectInformation, meta: { title: 'Information', permissions: ['ViewObjects'] } },
      { path: 'documentation', component: BookDocumentation, meta: { title: 'Documentation', permissions: ['ViewObjects'] } },
      { path: 'usage', component: ProjectUsage, meta: { title: 'Usage', permissions: ['ViewObjects'] } },
      {
        path: 'trained-models',
        component: ProjectTrainedModels,
        meta: { title: 'Trained Models', permissions: ['ViewObjects'] },
      },
    ],
  },
  {
    path: '/portfolios/:id',
    meta: { title: 'Portfolio', permissions: ['ViewObjects'] },
    name: 'portfolios.id',
    component: ViewPortfolio,
    children: [
      { path: '', redirect: { name: 'portfolio.id.information' }, name: 'portfolios.id.information' },
      { path: 'annotationprojects', component: PortfolioAnnotationProjects, meta: { title: 'Annotation projects', permissions: ['ViewObjects'] } },
      { path: 'data', name: 'portfolio.id.data', component: PortfolioData, meta: { title: 'Data', permissions: ['ViewObjects'] } },
      { path: 'information', name: 'portfolio.id.information', component: PortfolioInformation, meta: { title: 'Information', permissions: ['ViewObjects'] } },
      { path: 'models', name: 'portfolio.id.models', component: PortfolioModels, meta: { title: 'Models', permissions: ['ViewObjects'] } },
      { path: 'projects', component: PortfolioProjects, meta: { title: 'Projects', permissions: ['ViewObjects'] } },
      { path: 'documentation', component: BookDocumentation, meta: { title: 'Documentation', permissions: ['ViewObjects'] } },
      { path: 'jobs', component: PortfolioJobs, meta: { title: 'Jobs', permissions: ['ViewObjects'] } },
      { path: 'controls', component: PortfolioControls, meta: { title: 'Controls', permissions: ['ViewObjects'] } },
    ],
  },
  {
    path: '/annotationprojects/:id',
    name: 'annotationprojects.id',
    meta: { title: 'Annotation project', permissions: ['ViewObjects', 'AnnotateAnnotationProject'] },
    component: ViewAnnotationProject,
    children: [
      { path: '', component: AnnotationProjectInformation, meta: { title: 'Information' }, name: 'annotationprojects.id.information' },
      { path: 'information', component: AnnotationProjectInformation, meta: { title: 'Information' } },
      { path: 'labels', component: AnnotationProjectLabels, meta: { title: 'Label management' } },
      { path: 'history', component: AnnotationProjectHistory, meta: { title: 'History' } },
      { path: 'documentation', component: BookDocumentation, meta: { title: 'Documentation', permissions: ['ViewObjects'] } },
      { path: 'usage', component: AnnotationProjectUsage, meta: { title: 'Usage' } },
    ],
  },
  {
    path: '/data/:id/:version',
    name: 'data.id.version',
    meta: { title: 'Data version', permissions: ['ViewObjects'] },
    component: ViewDataVersion,
  },
  {
    path: '/data/:id',
    name: 'data.id',
    meta: { title: 'Data', permissions: ['ViewObjects'] },
    component: ViewDatum,
  },
  {
    path: '/models/:id',
    name: 'models.id',
    meta: { title: 'Models', permissions: ['ViewObjects'] },
    component: ViewModel,
  },
  {
    path: '/annotationprojects',
    meta: { title: 'Annotation projects', permissions: ['ViewObjects', 'AnnotateAnnotationProject'] },
    name: 'annotationprojects',
    component: ViewLibrary,
  },
  {
    path: '/data',
    meta: { title: 'Data', permissions: ['ViewObjects'] },
    name: 'data',
    component: ViewLibrary,
  },
  {
    path: '/projects',
    meta: { title: 'Projects', permissions: ['ViewObjects'] },
    name: 'projects',
    component: ViewLibrary,
  },
  {
    path: '/portfolios',
    meta: { title: 'Portfolios', permissions: ['ViewObjects'] },
    name: 'portfolios',
    component: ViewLibrary,
  },
  {
    path: '/models',
    meta: { title: 'Models', permissions: ['ViewObjects'] },
    name: 'models',
    component: ViewLibrary,
  },
  { path: '/response', component: ViewResponse },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes,
})

export default router

export function createRouterGuards() {
  router.beforeEach((to, _, next) => {
    if (!stores.user.authenticated && !to.meta.guest) {
      return next({ path: '/login' })
    }

    next()
  })

  router.afterEach((to) => {
    if (to.meta.title) {
      document.title = to.meta.title as string
    }
  })
}

export const route = router.currentRoute

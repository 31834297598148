export function useIsActivated() {
  const isActivated = ref<boolean>(true)

  onActivated(() => {
    isActivated.value = true
  })

  onDeactivated(() => {
    isActivated.value = false
  })

  return { isActivated }
}

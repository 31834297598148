<template>
  <VDialog placement="left" title="Notifications" :visible="visible" width="40%" @update:visible="hide">
    <VRadioGroup v-model="tab" style="margin-bottom: 30px" type="tabs-bordered">
      <VRadio label="notifications">Messages<VBadge :hidden="unreadNotificationsCount === 0" type="secondary" :value="unreadNotificationsCount" /></VRadio>
    </VRadioGroup>

    <KeepAlive>
      <VCard v-if="visible && tab === 'notifications'" title="Unread" type="primary">
        <template #header-right>
          <VButton icon="Check" icon-left label="Mark all as read" type="link" @click="readAll" />
        </template>

        <NotificationsTable ref="unread" />
      </VCard>
    </KeepAlive>

    <KeepAlive>
      <VCard v-if="visible && tab === 'notifications'" title="Read" type="info">
        <NotificationsTable ref="read" read />
      </VCard>
    </KeepAlive>
  </VDialog>
</template>

<script setup lang="ts">
import NotificationsTable from '../NotificationsTable.vue'

interface Props {
  visible: boolean
}

defineProps<Props>()

const emit = defineEmits<{
  (event: 'update:visible', visible: boolean): void
}>()

const read = ref<InstanceType<typeof NotificationsTable>>()
const unread = ref<InstanceType<typeof NotificationsTable>>()
const tab = ref('notifications')

const unreadNotificationsCount = computed(() => stores.initialState.unreadNotificationsCount)

onBeforeMount(() => {
  bus.on('notifications.show', () => (tab.value = 'notifications'))
})

onBeforeUnmount(() => {
  bus.off('notifications.show')
})

async function readAll() {
  await api.post('notifications/read')

  read.value?.fetch()
  if (unread.value) {
    unread.value.tableData = []
    unread.value.page = 1
    unread.value.total = 0
  }
  stores.initialState.unreadNotificationsCount = 0
}

function hide() {
  emit('update:visible', false)
}
</script>

<style lang="scss" scoped>
.v-dialog-wrapper {
  left: 60px;
}

.v-badge:not(.is-hidden) {
  margin-left: 5px;
}
</style>

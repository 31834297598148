/**
 * Filters entries from an object.
 * @param obj Object to filter items from.
 * @param customFilter: Filter which receives a `{key, value}` per item.
 * @returns Object with entries that do no match the filter removed.
 */
export const filterObject = <T>(obj: Record<string, T>, customFilter: ({ key, value }: { key: string; value: T | undefined }) => boolean): Record<string, T> =>
  Object.keys(obj)
    .filter((key) => customFilter({ key, value: obj[key] }))
    .reduce<Record<string, T>>((res, key: string) => {
      res[key] = obj[key] as T
      return res
    }, {})

/**
 * Filter an object based on attributes to keep.
 * @param obj Object to filter.
 * @param keys Attributes to keep.
 * @returns The filtered object.
 */
export const filterObjectByKeyInArray = <T>(obj: T, keys: string[]) => {
  const filtered: Partial<T> = {}
  for (const [key, value] of Object.entries(obj)) {
    if (keys.includes(key)) {
      filtered[key as keyof T] = value
    }
  }
  return filtered
}

/** Creates an array of attributes from objects in an array or object.
 * @example <caption>Retrieve array of ids from an array of objects.</caption>
 * const arr = [{'id':2, 'name': 'spam'}, {'id':3, 'name': 'eggs'}, {'name': 'bacon'}]
 * // Returns [2,3]
 * ArrayOfObjectsToArrayOfAttributeValues(arr, 'id')
 *
 * @param arr Objects in an array or object.
 * @param attribute Attribute to create an array of.
 * @returns Array with attributes.
 */
export const ArrayOfObjectsToArrayOfAttributeValues = <T, K extends keyof T>(arr: T[], attribute: K): T[K][] => {
  const result = []
  for (const item of arr) {
    result.push(item[attribute])
  }
  return result
}

/**
 * From an array of objects, returns the first object whose requested attribute matches the provided value.
 * @example <caption>Find the first object whose 'meat' attribute is false.</caption>
 * const arr = [{'name': 'spam', 'meat': true}, {'name': 'eggs', 'meat': false}, {'name': 'baked_beans', 'meat': false}]
 * // Returns {'name': 'eggs', 'meat': false}
 * ObjectWithAttributeOfObjects(arr, 'meat', false)
 *
 * @param arr Array of objects to search in.
 * @param attribute Attribute to check the value of.
 * @param value Value the attribute must match.
 * @returns
 */
export const ObjectWithAttributeOfObjects = <T, K extends keyof T>(arr: T[], attribute: K, value: T[K]) => {
  return arr.find((obj) => {
    return obj[attribute] === value
  })
}

/**
 * From an array of objects, returns the first object whose requested attribute matches the provided value.
 * @example <caption>Find the first object whose 'meat' attribute is false.</caption>
 * const arr = [{'name': 'spam', 'meat': true}, {'name': 'eggs', 'meat': false}, {'name': 'baked_beans', 'meat': false}]
 * // Returns  [{'name': 'spam', 'meat': true}, {'name': 'baked_beans', 'meat': false}]
 * ObjectWithAttributeOfObjects(arr, 'meat', true)
 * @param arr Array of objects to search in.
 * @param attribute Attribute to check the value of.
 * @param value Value the attribute must match.
 * @returns
 */
export const ObjectsWithAttributeOfObjects = <T, K extends keyof T>(arr: T[], attribute: K, value: T[K]) => {
  return arr.filter((obj) => {
    return obj[attribute] === value
  })
}

import { StoreDefinition } from 'pinia'

import { Verdict } from '@/types'

import { Relation, StateBook, StoreRelation } from '.'
import { AnnotationDatum } from './annotationdata'
import { AnnotationLabel, AnnotationLabelRelation } from './annotationlabels'
import { AnnotationProject, AnnotationProjectRelation } from './annotationprojects'
import { debounceOption, StandardBook, useStore } from './composables'
import { Book } from './index.d'
import { OrganisationRelation } from './organisation'
import { UserRelation } from './users'

interface AnnotationAnnotationChildren {
  firenze_organisation: OrganisationRelation
  firenze_user: UserRelation
  firenze_annotation_project: AnnotationProjectRelation
  firenze_annotation_label: AnnotationLabelRelation
}

export type AnnotationAnnotationRelation = Relation<AnnotationAnnotation, Appends, AnnotationAnnotationChildren>

export interface AnnotationAnnotationsQuery {
  pk: number
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface Appends {}

const defaultQuery = {
  default: {
    query: {
      default: {},
    },
  },
}

export interface AnnotationAnnotation extends Book {
  LIBRARY: 'annotationannotations'
  colour: string
  entry_name: string
  firenze_annotation_labels_ids: number[]
  firenze_annotation_labels: AnnotationLabel[]
  firenze_annotation_project_id: number
  firenze_annotation_project: AnnotationProject
  firenze_annotation_datum_id: number
  firenze_annotation_text: AnnotationDatum
  guideline: string
  verdict: Verdict
  comment: string
  flagged: boolean
}

export function createAnnotationAnnotationsStore<T extends StoreRelation<T, AnnotationAnnotationRelation, AnnotationAnnotation, AnnotationAnnotationChildren, Appends>>(id: string, initialRelations: T) {
  const library = 'annotationannotations'
  type StoreAnnotationAnnotation = StateBook<T['fields'], T['appends'], T['hidden_fields'], T['children'], AnnotationAnnotation, Appends, AnnotationAnnotationChildren, typeof library>

  return defineStore(
    id,
    () => {
      const annotationannotations = ref<StoreAnnotationAnnotation[]>([])
      const updateAvailable = ref(false)

      const relations = ref<AnnotationAnnotationRelation>({ ...initialRelations, query: defaultQuery })
      const { fetch, listenOnSocket, meta, relations: _relations, remove, resetQuery, sendRequest, update, updateQuery, updateSort } = useStore(library, relations, annotationannotations as Ref<StandardBook[]>, updateAvailable)

      return { annotationannotations, fetch, listenOnSocket, meta, relations, remove, resetQuery, sendRequest, update, updateAvailable, updateQuery, updateSort }
    },
    { ...debounceOption() }
  )
}

export const useAnnotationAnnotationsStore = createAnnotationAnnotationsStore('annotationannotations', {
  fields: ['id', 'comment', 'flagged', 'verdict', 'firenze_annotation_datum_id', 'last_updated', 'firenze_annotation_labels_ids', 'firenze_user_id'],
  children: {
    firenze_user: {},
  },
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useAnnotationAnnotationsStore as unknown as StoreDefinition, import.meta.hot))
}

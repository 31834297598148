<template>
  <div style="display: inline-flex">
    <VTooltip lazy>
      {{ formattedDate }}

      <template #content>
        {{ date }}
      </template>
    </VTooltip>
  </div>
</template>

<script setup lang="ts">
interface Props {
  date?: string
  alt?: string
}
const props = withDefaults(defineProps<Props>(), {
  date: undefined,
  alt: 'Not available',
})

const formattedDate = computed(() => (props.date ? dayjs(props.date).fromNow() : props.alt))
</script>

<style lang="scss" scoped>
.simple-date {
  cursor: copy;
}
</style>

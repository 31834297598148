import { $notify } from '@vartion/ui'

import { Status } from '@/stores/statuses'
import { Library } from '@/types'

interface BaseNotification {
  id: string
  firenze_user_id: number
  type: string
  data: Record<never, never>
  read_at: string | null
  date_of_creation: string
  last_updated: string
}
export interface ApplicationUpdatedNotification extends BaseNotification {
  type: 'ApplicationUpdatedNotification'
  data: {
    version: string
  }
}
export interface DataParsedNotification extends BaseNotification {
  type: 'DataParsedNotification'
  data: {
    datum_id: number
    data_version_id: number
    status: Status['status']
  }
}

export interface ModelTrainedNotification extends BaseNotification {
  type: 'ModelTrainedNotification'
  data: {
    project_id: number
    status: Status['status']
    model_type: string
    experiment_group_name: string
  }
}

export interface ModelExplainedNotification extends BaseNotification {
  type: 'ModelExplainedNotification'
  data: {
    status: Status['status']
    model_type: string
    model_name: string
    model_id: number
  }
}

export interface ModelStartedTrainingNotification extends BaseNotification {
  type: 'ModelStartedTrainingNotification'
  data: {
    project_id: number
    status: Status['status']
    model_type: string
    experiment_group_name: string
  }
}

export interface ModelInferredNotification extends BaseNotification {
  type: 'ModelInferredNotification'
  data: {
    model_id: number
    status: Status['status']
  }
}

export interface ModelHostingStoppedIdleNotification extends BaseNotification {
  type: 'ModelHostingStoppedIdleNotification'
  data: {
    model_id: number
  }
}

export interface AutoMLProjectCreatedNotification extends BaseNotification {
  type: 'AutoMLProjectCreatedNotification'
  data: {
    portfolio_id: number
    project_id: number
  }
}

export interface ModelEvaluatedNotification extends BaseNotification {
  type: 'ModelEvaluatedNotification'
  data: {
    model_id: number
    status: Status['status']
  }
}

export interface ModelRetrainingStartedNotification extends BaseNotification {
  type: 'ModelRetrainingStartedNotification'
  data: {
    project_id: number
  }
}

export interface AnnotationProjectExportedNotification extends BaseNotification {
  type: 'AnnotationProjectExportedNotification'
  data: {
    annotation_project_id: number
    datum_id?: number
    data_version_id?: number
    status: Status['status']
  }
}

export interface MultipleAnnotationProjectsExportedNotification extends BaseNotification {
  type: 'MultipleAnnotationProjectsExportedNotification'
  data: {
    portfolio_id: number
    datum_id?: number
    data_version_id?: number
    status: Status['status']
  }
}

export interface DataVersionExportedNotification extends BaseNotification {
  type: 'DataVersionExportedNotification'
  data: {
    datum_id?: number
    data_version_id?: number
    status: Status['status']
  }
}

export interface StorageConfigAddedNotification extends BaseNotification {
  type: 'StorageConfigAddedNotification'
  data: {
    status: Status['status']
  }
}

export interface ObjectAssignedNotification extends BaseNotification {
  type: 'ObjectAssignedNotification'
  data: {
    object_id: number
    library: Library
    firenze_project_id?: number
    firenze_datum_id?: number
  }
}

export type Notification =
  | DataParsedNotification
  | ModelTrainedNotification
  | ModelExplainedNotification
  | ModelStartedTrainingNotification
  | ModelInferredNotification
  | ModelHostingStoppedIdleNotification
  | AutoMLProjectCreatedNotification
  | ModelEvaluatedNotification
  | ModelRetrainingStartedNotification
  | AnnotationProjectExportedNotification
  | ApplicationUpdatedNotification
  | DataVersionExportedNotification
  | MultipleAnnotationProjectsExportedNotification
  | StorageConfigAddedNotification
  | ObjectAssignedNotification

type Callback<T extends Notification = Notification> = (notification: T) => any

export const notifications = {
  callbacks: {} as Record<Notification['type'], Callback<any>[]>,

  listen() {
    socket.user.on<Notification>('notification', (notification) => {
      if (notification.type in this.callbacks) {
        for (const callback of this.callbacks[notification.type]) {
          callback(notification)
        }
        return
      }

      const title = this.createTitle(notification)
      const message = this.createMessage(notification)

      $notify({
        title,
        message,
        onClick: () => {
          open(notification)
        },
      })

      // Only database notifications should increase the count.
      if (!['ApplicationUpdatedNotification'].includes(notification.type)) {
        stores.initialState.unreadNotificationsCount++
        bus.emit('notify')
      }
    })
  },

  on<T extends Notification>(type: Notification['type'], callback: Callback<T>) {
    this.callbacks[type] ??= []
    this.callbacks[type].push(callback)
  },

  // The type is not really `Notification`.
  // It only contains `type` and `data`.
  // In order to keep the type guarding we type it as `Notification`.
  createTitle(notification: Notification) {
    if (notification.type === 'DataParsedNotification') {
      if (notification.data.status === 'completed') {
        return 'Data parsed'
      } else if (notification.data.status === 'error') {
        return 'Data parsing failed'
      }
    } else if (notification.type === 'ModelTrainedNotification') {
      if (notification.data.status === 'completed') {
        return 'Model trained'
      } else if (notification.data.status === 'error') {
        return 'Model training failed'
      }
    } else if (notification.type === 'ModelExplainedNotification') {
      if (notification.data.status === 'completed') {
        return 'Model explained'
      }
    } else if (notification.type === 'ModelStartedTrainingNotification') {
      if (notification.data.status === 'running') {
        return 'Model training started'
      }
    } else if (notification.type === 'ModelInferredNotification') {
      if (notification.data.status === 'completed') {
        return 'Model inferred'
      } else if (notification.data.status === 'error') {
        return 'Model inference failed'
      }
    } else if (notification.type === 'ModelHostingStoppedIdleNotification') {
      return 'Model hosting stopped'
    } else if (notification.type === 'AutoMLProjectCreatedNotification') {
      return 'Project has been created for AutoML run'
    } else if (notification.type === 'ModelEvaluatedNotification') {
      if (notification.data.status === 'completed') {
        return 'Model evaluated'
      } else if (notification.data.status === 'error') {
        return 'Model evaluation failed'
      }
    } else if (notification.type === 'ModelRetrainingStartedNotification') {
      return 'Model retraining queued'
    } else if (notification.type === 'AnnotationProjectExportedNotification') {
      if (notification.data.status === 'completed') {
        return 'Annotation project exported'
      } else if (notification.data.status === 'error') {
        return 'Annotation project export failed'
      }
    } else if (notification.type === 'MultipleAnnotationProjectsExportedNotification') {
      if (notification.data.status === 'completed') {
        return 'Multiple annotation projects exported'
      } else if (notification.data.status === 'error') {
        return 'Multiple annotation projects export failed'
      }
    } else if (notification.type === 'DataVersionExportedNotification') {
      if (notification.data.status === 'completed') {
        return 'Data version exported'
      } else if (notification.data.status === 'error') {
        return 'Data version exporting failed'
      }
    } else if (notification.type === 'StorageConfigAddedNotification') {
      if (notification.data.status === 'error') return 'Storage config not active'
      return 'Storage config added'
    } else if (notification.type === 'ObjectAssignedNotification') {
      return 'Object assigned'
    }

    return notification.type
  },

  createMessage(notification: Notification) {
    if (notification.type === 'DataParsedNotification') {
      if (notification.data.status === 'completed') {
        return 'Your data was successfully parsed.'
      } else if (notification.data.status === 'error') {
        return 'Your data failed to parse.'
      }
    } else if (notification.type === 'ModelTrainedNotification') {
      if (notification.data.status === 'completed') {
        return `A model of type ${notification.data.model_type} from ${notification.data.experiment_group_name} was successfully trained in project ${notification.data.project_id}.`
      } else if (notification.data.status === 'error') {
        return `The training of a model connected to project ${notification.data.project_id} and ${notification.data.experiment_group_name} has failed.`
      }
    } else if (notification.type === 'ModelExplainedNotification') {
      if (notification.data.status === 'completed') {
        return `Model ${notification.data.model_name} of type ${notification.data.model_type} was successfully explained.`
      }
    } else if (notification.type === 'ModelStartedTrainingNotification') {
      if (notification.data.status === 'running') {
        return `A model of ${notification.data.experiment_group_name} type ${notification.data.model_type} started training in project ${notification.data.project_id}.`
      }
    } else if (notification.type === 'ModelInferredNotification') {
      if (notification.data.status === 'completed') {
        return 'Your model was successfully inferred.'
      } else if (notification.data.status === 'error') {
        return 'Your model failed to infer.'
      }
    } else if (notification.type === 'ModelHostingStoppedIdleNotification') {
      return 'Your model was idle for a while, model is shutting down.'
    } else if (notification.type === 'AutoMLProjectCreatedNotification') {
      return `Project ${notification.data.project_id} has been created in Portfolio ${notification.data.portfolio_id}. Experiments have been queued.`
    } else if (notification.type === 'ModelEvaluatedNotification') {
      if (notification.data.status === 'completed') {
        return 'Your model was successfully evaluated.'
      } else if (notification.data.status === 'error') {
        return 'Your model failed to evaluate.'
      }
    } else if (notification.type === 'ModelRetrainingStartedNotification') {
      return 'Model retraining has been queued and added to the project.'
    } else if (notification.type === 'AnnotationProjectExportedNotification') {
      if (notification.data.status === 'completed') {
        return 'Annotation project was successfully exported.'
      } else if (notification.data.status === 'error') {
        return 'Annotation project exporting failed.'
      }
    } else if (notification.type === 'MultipleAnnotationProjectsExportedNotification') {
      if (notification.data.status === 'completed') {
        return 'Multiple annotation project was successfully exported.'
      } else if (notification.data.status === 'error') {
        return 'Multiple annotation project exporting failed.'
      }
    } else if (notification.type === 'DataVersionExportedNotification') {
      if (notification.data.status === 'completed') {
        return 'Data version was successfully exported.'
      } else if (notification.data.status === 'error') {
        return 'Data version exporting failed.'
      }
    } else if (notification.type === 'StorageConfigAddedNotification') {
      if (notification.data.status === 'error') return 'Could not connect to storage.'
      return 'Storage config has been added successfully.'
    } else if (notification.type === 'ObjectAssignedNotification') {
      return `A ${stores.jsons.renames.libraries[notification.data.library]} object with id ${notification.data.object_id} has been assigned to you.`
    }

    return notification.type
  },
}
notifications.listen()

export function open(notification: Notification) {
  if (notification.type === 'DataParsedNotification') {
    router.push(`/data/${notification.data.datum_id}/${notification.data.data_version_id}`)
  } else if (notification.type === 'ModelTrainedNotification') {
    if (notification.data.status === 'error') {
      router.push(`/projects/${notification.data.project_id}/experiments`)
    } else {
      router.push(`/projects/${notification.data.project_id}/trained-models`)
    }
  } else if (notification.type === 'ModelExplainedNotification') {
    router.push(`/models/${notification.data.model_id}/explainability`)
  } else if (notification.type === 'ModelStartedTrainingNotification') {
    router.push(`/projects/${notification.data.project_id}/experiments`)
  } else if (notification.type === 'ModelInferredNotification') {
    router.push(`/models/${notification.data.model_id}`)
  } else if (notification.type === 'ModelHostingStoppedIdleNotification') {
    router.push(`/models/${notification.data.model_id}`)
  } else if (notification.type === 'AutoMLProjectCreatedNotification') {
    router.push(`/projects/${notification.data.project_id}`)
  } else if (notification.type === 'ModelEvaluatedNotification') {
    router.push(`/models/${notification.data.model_id}`)
  } else if (notification.type === 'ModelRetrainingStartedNotification') {
    router.push(`/projects/${notification.data.project_id}`)
  } else if (notification.type === 'AnnotationProjectExportedNotification') {
    if (notification.data.status === 'error') {
      router.push(`/annotationprojects/${notification.data.annotation_project_id}`)
    } else {
      router.push(`/data/${notification.data.datum_id}/${notification.data.data_version_id}`)
    }
  } else if (notification.type === 'DataVersionExportedNotification') {
    if (notification.data.status === 'error') {
      router.push('/data')
    } else {
      router.push(`/data/${notification.data.datum_id}/${notification.data.data_version_id}`)
    }
  } else if (notification.type === 'MultipleAnnotationProjectsExportedNotification') {
    if (notification.data.status === 'error') {
      router.push(`/portfolios/${notification.data.portfolio_id}`)
    } else {
      router.push(`/data/${notification.data.datum_id}/${notification.data.data_version_id}`)
    }
  } else if (notification.type === 'ObjectAssignedNotification') {
    let routeParams = {}

    if (notification.data.library === 'experimentgroups') {
      routeParams = { path: `/projects/${notification.data.firenze_project_id}`, query: { experimentGroupId: `${notification.data.object_id}` } }
    } else if (notification.data.library === 'experiments') {
      routeParams = { path: `/projects/${notification.data.firenze_project_id}`, query: { experimentId: `${notification.data.object_id}` } }
    } else if (notification.data.library === 'annotationprojects') {
      routeParams = { path: `/annotationprojects/${notification.data.object_id}` }
    } else if (notification.data.library === 'dataversions') {
      routeParams = { path: `/data/${notification.data.firenze_datum_id}/${notification.data.object_id}` }
    } else {
      routeParams = { path: `/${notification.data.library}/${notification.data.object_id}` }
    }
    router.push(routeParams)
  }
  bus.emit('dialog.hide', 'notifications')
  markAsRead(notification)
}

async function markAsRead(row: Notification) {
  if (row.read_at === null) {
    await api.post('/notifications/read', { ids: [row.id] })
    row.read_at = dayjs().toISOString()
  }
}

import dayjs from 'dayjs'
import { set } from 'lodash-es'

const cache: Record<string, string> = {}

export type CacheRecord = string | number | unknown[] | object | null

class Cache {
  tags: string[] = []
  prefix = 'cache:'

  constructor({ tags = [] }: { tags?: string[] } = {}) {
    this.tags = tags
    this.prefix = `cache:${this.tags.join(':')}`

    setInterval(this.flushExpired.bind(this), 60000)
  }

  flush() {
    for (const key in cache) {
      if (key.startsWith(this.prefix)) {
        delete cache[key]
      }
    }
  }

  flushExpired() {
    for (const key in cache) {
      const item = cache[key]
      if (!item) continue

      const meta = item.split(':')

      if (this.isExpired(meta)) {
        delete cache[key]
      }
    }
  }

  list() {
    const list: Record<string, string> = {}

    for (const key in cache) {
      if (key.startsWith(this.prefix)) {
        set(list, key, cache[key])
      }
    }

    return new Promise<Record<string, string>>((resolve) => resolve(list))
  }

  createKey(key: CacheRecord) {
    return `${this.prefix}:${JSON.stringify(key)}`
  }

  createValue(value: CacheRecord, date: dayjs.Dayjs) {
    return `${date.unix()}:${JSON.stringify(value)}`
  }

  has(key: CacheRecord) {
    return this.get(key) !== null
  }

  async put(key: CacheRecord, value: CacheRecord, date = dayjs().add(1, 'year')) {
    await this.store(this.createKey(key), this.createValue(value, date))
  }

  // eslint-disable-next-line require-await
  async store(key: string, value: string) {
    cache[key] = value
  }

  isExpired(meta: string[]) {
    const timestamp = meta[0]

    return dayjs().isAfter(dayjs(Number(timestamp) * 1000))
  }

  getValue(meta: string[]) {
    return meta.slice(1).join(':')
  }

  get<T = any>(key: CacheRecord, defaultValue: any = null): Promise<T> {
    key = this.createKey(key)

    const item = cache[key]
    if (!item) return defaultValue

    const meta = item.split(':')

    if (this.isExpired(meta)) {
      delete cache[key]
      return defaultValue
    }

    return JSON.parse(this.getValue(meta))
  }
}

export { Cache }

import { StoreDefinition } from 'pinia'

import { Relation, StateBook, StoreRelation } from '.'
import { debounceOption, StandardBook, useStore } from './composables'
import { DataVersion, DataVersionRelation } from './dataversions'
import { OrganisationRelation } from './organisation'

interface DataVersionControlChildren {
  firenze_organisation: OrganisationRelation
  firenze_data_version: DataVersionRelation
}

export type DataVersionControlRelation = Relation<DataVersionControl, Appends, DataVersionControlChildren>

export interface DataVersionsControlsQuery {
  pk: number
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface Appends {}

const defaultQuery = {
  default: {},
}

export interface DataVersionControl {
  LIBRARY: 'dataversionscontrols'
  id: number
  firenze_data_version: DataVersion
  verified_license_description: string
  verified_license_status: string
  verified_data_compliant_description: string
  verified_data_compliant_status: string
  verified_data_content_description: string
  verified_data_content_status: string
  verified_data_representation_description: string
  verified_data_representation_status: string
  verified_data_bias_description: string
  verified_data_bias_status: string
  verified_data_distribution_description: string
  verified_data_distribution_status: string
  verified_data_incorrect_description: string
  verified_data_incorrect_status: string
}

export function createDataVersionsControlsStore<T extends StoreRelation<T, DataVersionControlRelation, DataVersionControl, DataVersionControlChildren, Appends>>(id: string, initialRelations: T) {
  const library = 'dataversionscontrols'
  type StoreDataVersionControl = StateBook<T['fields'], T['appends'], T['hidden_fields'], T['children'], DataVersionControl, Appends, DataVersionControlChildren, typeof library>

  return defineStore(
    id,
    () => {
      const dataversionscontrols = ref<StoreDataVersionControl[]>([])
      const updateAvailable = ref(false)

      const relations = ref<DataVersionControlRelation>({ ...initialRelations, query: defaultQuery })
      const { fetch, listenOnSocket, meta, relations: _relations, remove, resetQuery, sendRequest, update, updateQuery, updateSort } = useStore(library, relations, dataversionscontrols as Ref<StandardBook[]>, updateAvailable)

      return { dataversionscontrols, fetch, listenOnSocket, meta, relations, remove, resetQuery, sendRequest, update, updateAvailable, updateQuery, updateSort }
    },
    { ...debounceOption() }
  )
}

export const useDataVersionsControlsStore = createDataVersionsControlsStore('dataversionscontrols', {})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useDataVersionsControlsStore as unknown as StoreDefinition, import.meta.hot))
}

import ReconnectingWebSocket from 'reconnecting-websocket'

import { Optional } from '@/types'

const protocol = window.location.protocol === 'https:' ? 'wss' : 'ws'

type Callback<T> = (data: T) => any | Promise<any>

interface Callbacks<T = any> {
  [key: string]: Callback<T>[]
}

const createSocket = () => ({
  socket: undefined as Optional<ReconnectingWebSocket>,
  callbacks: {} as Callbacks,

  listen(roomName: string) {
    if (this.socket) this.leave()

    this.socket = new ReconnectingWebSocket(`${protocol}://${window.location.host.replace(':3000', '')}/ws/${roomName}`)

    this.socket.onmessage = (event) => {
      const { key, data }: { key: string; data: unknown } = JSON.parse(event.data)

      const callbackEntry = this.callbacks[key]
      if (callbackEntry) {
        callbackEntry.forEach((callback) => callback(data))
      } else {
        console.warn(`No callback registered for message: ${key}`)
      }
    }

    return this
  },

  leave() {
    this.socket?.close()
    this.socket = undefined
    this.callbacks = {}
    return this
  },

  /**
   * Only for presence.
   */
  here(callback: Callback<number[]>) {
    this.on('presence', callback)
    this.send('presence', {})

    return this
  },

  /**
   * Only for presence.
   */
  joining(callback: Callback<number>) {
    this.on('joining', callback)
    return this
  },

  /**
   * Only for presence.
   */
  leaving(callback: Callback<number>) {
    this.on('leaving', callback)
    return this
  },

  on<T = unknown>(key: string, callback: Callback<T>) {
    ;(this.callbacks[key] ??= []).push(callback)
    return this
  },

  async send(key: string, data?: unknown) {
    if (!this.socket) throw new Error(`No socket available when trying to send on: ${key}`)

    while (this.socket.readyState === 0) {
      await delay()
    }

    if (this.socket.readyState !== 1) {
      throw new Error('Not connected')
    }
    this.socket.send(JSON.stringify({ key, data }))

    return this
  },

  remove(key: string, index = -1) {
    if (index >= 0) {
      const callbackEntry = this.callbacks[key]
      callbackEntry && callbackEntry.splice(index, 1)
    } else {
      this.callbacks[key] = []
    }

    return this
  },
})

export type Socket = ReturnType<typeof createSocket>

export const socket = {
  create: createSocket,
  user: createSocket(),
  organisation: createSocket(),
}

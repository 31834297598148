import mitt, { Emitter } from 'mitt'

import { ImageVersion } from '@/stores/imageVersions'

type Dialog = 'notifications'

type Events = {
  'ViewDatum.load': undefined
  'ViewModel.load': undefined
  'DialogAnnotationProjectAdd.fetch': undefined
  'DialogDatumAdd.load': undefined
  'DialogModelAdd.load': undefined
  'DialogImageVersionEdit.fetch': undefined
  'DialogProjectAdd.fetch': undefined
  'dialog.show': Dialog
  'dialog.hide': Dialog
  'dialog.toggle': Dialog
  'notifications.show': undefined
  notify: undefined
  'kubeConfigs.fetch': undefined
  'ViewAdministration.imageVersions.fetch': ImageVersion
  'ViewAdministration.organisations.fetch': undefined
  'TableData.load': undefined
  'TableModels.load': undefined
  'ViewAnnotationProject.load': undefined
  'ViewAnnotationProject.loadLabels': undefined
  'ViewPortfolio.load': undefined
  'ViewProject.load': undefined
  'report-submitted-stop-loading': undefined
}

const once = <T extends keyof Events>(event: T, callback: (params: Events[T]) => void) => {
  const internalCallback = (params: Events[T]) => {
    callback(params)
    bus.off(event, internalCallback)
  }

  bus.on(event, internalCallback)
}

export type Bus = Emitter<Events> & { once: typeof once }

export const bus: Bus = { ...mitt<Events>(), once }
bus.once = once

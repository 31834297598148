import { App } from 'vue'

import { Library, Query } from '@/types'

import { useAdminStore } from './admin'
import { useAnnotationAnnotationsStore } from './annotationannotations'
import { useAnnotationDataStore } from './annotationdata'
import { useAnnotationLabelsStore } from './annotationlabels'
import { useAnnotationProjectsStore } from './annotationprojects'
import { useAvatars } from './avatars'
import { useDataStore } from './data'
import { useDataVersionsStore } from './dataversions'
import { useDataVersionsControlsStore } from './dataversionscontrols'
import { useExperimentGroupsStore } from './experimentgroups'
import { useExperimentsStore } from './experiments'
import { useInitialStateStore } from './initialState'
import { useJobsStore } from './jobs'
import { useJsonsStore } from './jsons'
import { useLoadingStore } from './loading'
import { useModelsStore } from './models'
import { useModelsControlsStore } from './modelscontrols'
import { useOrganisationStore } from './organisation'
import { usePortfoliosStore } from './portfolios'
import { useProjectsStore } from './projects'
import { useReportsStore } from './reports'
import { useStatusesStore } from './statuses'
import { useUserStore } from './user'
import { useUsersStore } from './users'
import { useUserSettingsStore } from './usersettings'

export interface Stores {
  admin: ReturnType<typeof useAdminStore>
  annotationannotations: ReturnType<typeof useAnnotationAnnotationsStore>
  annotationlabels: ReturnType<typeof useAnnotationLabelsStore>
  annotationprojects: ReturnType<typeof useAnnotationProjectsStore>
  annotationdata: ReturnType<typeof useAnnotationDataStore>
  avatars: ReturnType<typeof useAvatars>
  data: ReturnType<typeof useDataStore>
  dataversions: ReturnType<typeof useDataVersionsStore>
  dataversionscontrols: ReturnType<typeof useDataVersionsControlsStore>
  experiments: ReturnType<typeof useExperimentsStore>
  experimentgroups: ReturnType<typeof useExperimentGroupsStore>
  initialState: ReturnType<typeof useInitialStateStore>
  jobs: ReturnType<typeof useJobsStore>
  jsons: ReturnType<typeof useJsonsStore>
  loading: ReturnType<typeof useLoadingStore>
  models: ReturnType<typeof useModelsStore>
  modelscontrols: ReturnType<typeof useModelsControlsStore>
  organisation: ReturnType<typeof useOrganisationStore>
  portfolios: ReturnType<typeof usePortfoliosStore>
  projects: ReturnType<typeof useProjectsStore>
  reports: ReturnType<typeof useReportsStore>
  statuses: ReturnType<typeof useStatusesStore>
  user: ReturnType<typeof useUserStore>
  users: ReturnType<typeof useUsersStore>
  usersettings: ReturnType<typeof useUserSettingsStore>
}

export const stores = {
  install(app: App) {
    const stores: Stores = {
      admin: useAdminStore(),
      annotationannotations: useAnnotationAnnotationsStore(),
      annotationlabels: useAnnotationLabelsStore(),
      annotationprojects: useAnnotationProjectsStore(),
      annotationdata: useAnnotationDataStore(),
      avatars: useAvatars(),
      data: useDataStore(),
      dataversions: useDataVersionsStore(),
      dataversionscontrols: useDataVersionsControlsStore(),
      experiments: useExperimentsStore(),
      experimentgroups: useExperimentGroupsStore(),
      initialState: useInitialStateStore(),
      jobs: useJobsStore(),
      jsons: useJsonsStore(),
      loading: useLoadingStore(),
      models: useModelsStore(),
      modelscontrols: useModelsControlsStore(),
      organisation: useOrganisationStore(),
      portfolios: usePortfoliosStore(),
      projects: useProjectsStore(),
      reports: useReportsStore(),
      statuses: useStatusesStore(),
      user: useUserStore(),
      users: useUsersStore(),
      usersettings: useUserSettingsStore(),
    }

    window.stores = stores

    app.config.globalProperties.$stores = stores
  },
}

type RequireOnlyOne<T, TKeys extends keyof T = keyof T> = Pick<T, Exclude<keyof T, TKeys>> &
  {
    [K in TKeys]-?: Required<Pick<T, K>> & Partial<Record<Exclude<TKeys, K>, undefined>>
  }[TKeys]

type FieldsOrHiddenFields<TBook> = RequireOnlyOne<{ fields: (keyof TBook)[]; hidden_fields: (keyof TBook)[] }, 'fields' | 'hidden_fields'>

export type Relation<TBook, TAppends, TChildren> = Partial<FieldsOrHiddenFields<TBook>> & {
  appends?: (keyof TAppends)[]
  children?: Partial<TChildren>
  query?: Query
  TAppends?: TAppends
  live?: boolean
}

// eslint-disable-next-line
export type StoreRelation<
  T extends StoreRelation<T, TRelation, TBook, TChildren, TAppends>,
  TRelation extends Relation<TBook, TAppends, TChildren>,
  TBook,
  TChildren extends Partial<Record<keyof TBook, object>>,
  TAppends
> = Relation<TBook, TAppends, TChildren> & {
  [K in keyof T]?: K extends keyof TRelation ? TRelation[K] : never
} & {
  // children?: Partial<TChildren> & { [K in keyof T['children']]?: K extends keyof TChildren ? TChildren[K] : never }
  children?: Partial<TChildren> & { [K in keyof T['children']]?: K extends keyof TChildren ? T['children'][K] : never }
}

// eslint-disable-next-line
export type StateBook<
  TFieldKeys extends (keyof TBook)[] | undefined,
  TAppendKeys extends (keyof TAppends)[] | undefined,
  THiddenFieldKeys extends (keyof TBook)[] | undefined,
  TChildren extends Partial<Record<keyof TBook, object>> | undefined,
  TBook,
  TAppends,
  TAllChildren,
  TLibrary extends Library,
  TParent = null
> = Omit<
  // Remove all possible children which are added for typing.
  Omit<
    // Remove all passed hidden fields.
    Pick<TBook, TFieldKeys extends (keyof TBook)[] ? TFieldKeys[number] : keyof TBook> & Pick<TAppends, TAppendKeys extends (keyof TAppends)[] ? TAppendKeys[number] : never>,
    THiddenFieldKeys extends (keyof TBook)[] ? THiddenFieldKeys[number] : never
  >,
  keyof TAllChildren
> & {
  // Add back all requested children with nesting.
  [key in keyof Pick<TChildren, Extract<keyof TBook, keyof TChildren>>]: TBook[key] extends unknown[]
    ? TChildren[key] extends { fields?: infer Fields; appends?: infer Appends; hidden_fields?: infer HiddenFields; children?: infer Children }
      ? (StateBook<
          TChildren[key]['fields'],
          TChildren[key]['appends'],
          TChildren[key]['hidden_fields'],
          TChildren[key]['children'],
          TBook[key][number],
          Required<TAllChildren[key]>['TAppends'],
          Required<Required<TAllChildren[key]>['children']>,
          TBook[key][number]['LIBRARY'],
          StateBook<TFieldKeys, TAppendKeys, THiddenFieldKeys, TChildren, TBook, TAppends, TAllChildren, TLibrary>
        > & { id: number })[]
      : never
    : TChildren[key] extends { fields?: infer Fields; appends?: infer Appends; hidden_fields?: infer HiddenFields; children?: infer Children }
    ? StateBook<
        TChildren[key]['fields'],
        TChildren[key]['appends'],
        TChildren[key]['hidden_fields'],
        TChildren[key]['children'],
        TBook[key],
        Required<TAllChildren[key]>['TAppends'],
        Required<Required<TAllChildren[key]>['children']>,
        TBook[key]['LIBRARY'],
        StateBook<TFieldKeys, TAppendKeys, THiddenFieldKeys, TChildren, TBook, TAppends, TAllChildren, TLibrary>
      > & { id: number }
    : never
} & { id: number; LIBRARY: TLibrary; parent: TParent } // `id` and `LIBRARY` are always returned. `LIBRARY` should be typed in the store.

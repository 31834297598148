import { StoreDefinition } from 'pinia'

import { UserPermission } from '@/types'

import { Relation, StateBook, StoreRelation } from '.'
import { debounceOption, useStore } from './composables'
import { Book } from './index.d'
import { OrganisationRelation } from './organisation'

interface UserChildren {
  firenze_organisation: OrganisationRelation
}

export type UserRelation = Relation<User, Appends, UserChildren>

export interface UsersQuery {
  pk: number
  email__icontains: string
}

interface Appends {
  full_name: string
}

const defaultQuery = {
  default: {
    email__icontains: '',
  },
}

export interface User extends Omit<Book, 'firenze_user' | 'firenze_user_id'> {
  LIBRARY: 'users'
  email: string
  first_name: string
  last_name: string
  function: string
  role: 'admin' | 'developer' | 'viewer' | 'annotator'
  permissions: UserPermission[]
  is_active: boolean
  is_superuser: boolean
  is_staff: boolean
  // [key: string]: User[keyof User]
}

export function createUsersStore<T extends StoreRelation<T, UserRelation, User, UserChildren, Appends>>(id: string, initialRelations: T) {
  const library = 'users'
  type StoreUser = StateBook<T['fields'], T['appends'], T['hidden_fields'], T['children'], User, Appends, UserChildren, typeof library>

  return defineStore(
    id,
    () => {
      const users = ref<StoreUser[]>([])
      const updateAvailable = ref(false)

      const relations = ref<UserRelation>({ ...initialRelations, query: defaultQuery })
      const { fetch, listenOnSocket, meta, relations: _relations, remove, resetQuery, sendRequest, update, updateQuery, updateSort } = useStore(library, relations, users, updateAvailable, 10, 'email', 'asc')

      return { users, fetch, listenOnSocket, meta, relations, remove, resetQuery, sendRequest, update, updateAvailable, updateQuery, updateSort }
    },
    { ...debounceOption() }
  )
}

export const useUsersStore = createUsersStore('users', {
  appends: ['full_name'],
  fields: ['id', 'first_name', 'last_name', 'role'],
})

export type UsersStoreUser = (typeof stores.users.users)[number]

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useUsersStore as unknown as StoreDefinition, import.meta.hot))
}

import { StoreDefinition } from 'pinia'

import { Library } from '@/types'

import { AlertLevel } from './types'

export interface Admin {
  display_system_alert: boolean
  system_alert_message: string
  system_alert_level: AlertLevel
}

export function createAdminStore(id: string) {
  return defineStore(id, () => {
    const library: Library = 'admin'
    const admin = ref<Admin>({
      display_system_alert: false,
      system_alert_level: 'info',
      system_alert_message: '',
    })

    function listenOnSocket() {
      socket.organisation.on('update_store', ({ action, library, data }: { action: string; library: Library; data: Admin }) => {
        if (library !== 'admin') return
        if (action !== 'update') return
        update({ book: data })
      })
    }

    async function fetch() {
      const { data: adminData } = await api.get('admin')
      admin.value = adminData
      listenOnSocket()
    }

    function update({ book }: { book: Partial<Admin> }) {
      admin.value = Object.assign(admin.value, book)
    }

    return { admin, library, fetch, update }
  })
}

export const useAdminStore = createAdminStore('admin')

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useAdminStore as unknown as StoreDefinition, import.meta.hot))
}

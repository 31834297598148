import { StoreDefinition } from 'pinia'

import { Library, OrganisationPermission } from '@/types'

import { Relation } from '.'
import { Book } from './index.d'

export interface GlobalOrganisation extends Organisation {
  permissions: OrganisationPermission[]
}
interface OrganisationChildren {
  firenze_kube_config: { fields?: string[]; hidden_fields?: string[]; appends?: string[]; query?: Record<string, unknown> }
}

export type OrganisationRelation = Relation<Organisation, Appends, OrganisationChildren>

export interface OrganisationQuery {
  pk: number
  country: string
}

interface Appends {
  data_version_storage_size: number
  model_storage_size: number
  report_storage_size: number
  permissions: OrganisationPermission[]
}

const defaultQuery = {
  default: {},
}

export interface Organisation extends Book {
  LIBRARY: 'organisations'
  address: string
  country: string
  entry_name: string
  organisation_admin: number
  phone_number: string
  technical_contact: string
  firenze_active_storage_id: number
  firenze_kube_config_id: number
  firenze_kube_config: KubeConfig
  model_storage_size: number
  data_version_storage_size: number
  report_storage_size: number
}

interface KubeConfig extends Book {
  config: string
}

export function createOrganisationStore(id: string) {
  return defineStore(id, () => {
    const library: Library = 'organisation'
    const organisation = ref<GlobalOrganisation>()

    async function load(id: number) {
      await fetch({ id })
      listenOnSocket()
    }

    async function fetch({ id, storage }: { id: number; storage?: boolean }) {
      const { data } = await api.post(`organisations/searches/${id}`, { forms: { storage: !!storage } })
      update(data)
    }

    function update(_organisation: Partial<Organisation>) {
      organisation.value = { ...organisation.value, ..._organisation } as GlobalOrganisation
    }

    function remove() {
      delete organisation.value
    }

    function forceDeleteUser({ id }: { id: number }) {
      throw new Error(`Not implemented.${id}`)
    }

    function listenOnSocket() {
      socket.organisation.on('update_store', ({ action, id, library, data }: { action: string; id: number; library: Library; data: Partial<Organisation> }) => {
        if (library !== 'organisations' && action !== 'update' && id !== organisation.value?.id) return
        update(data)
      })
    }

    return { fetch, library, load, organisation, update, remove, forceDeleteUser }
  })
}

export const useOrganisationStore = createOrganisationStore('organisation')

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useOrganisationStore as unknown as StoreDefinition, import.meta.hot))
}

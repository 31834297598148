<template>
  <div v-if="loaded">
    <Transition appear name="v-slide-out-right-fade">
      <Auth v-if="$route.meta.guest" key="login" />
    </Transition>

    <Transition appear name="v-fade">
      <VContainer v-if="authenticatedAndReady" key="app">
        <VAside>
          <TheSideBarLeft />
        </VAside>

        <VContainer main vertical>
          <Teleport to="body">
            <VLoadingBar v-show="loadingBarVisible" class="app-loader" />
          </Teleport>
          <TheSystemBar v-if="admin?.display_system_alert" :type="admin.system_alert_level"> <VIcon :icon="systemAlertIcons[admin.system_alert_level]" /> {{ admin.system_alert_message }} </TheSystemBar>
          <VHeader>
            <TheTopBar />
          </VHeader>

          <VContainer>
            <VMain ref="scroll" style="scroll-behavior: smooth">
              <RouterView v-slot="{ Component }">
                <Transition mode="out-in" name="v-fade-fast">
                  <Component :is="Component" v-if="userAuthorised" :key="key" />
                </Transition>
              </RouterView>

              <ScrollToTopButton v-if="scrollElement" :element="scrollElement" :top="scrollTop" />
            </VMain>
          </VContainer>
        </VContainer>

        <GlobalDialogs />
      </VContainer>
    </Transition>
  </div>
</template>

<script setup lang="ts">
import { VIconIcon } from '@vartion/ui'

import { route } from './router'

const systemAlertIcons: Record<string, VIconIcon> = {
  success: 'Check',
  info: 'InfoCircle',
  warning: 'QuestionCircle',
  error: 'CloseCircle',
}

const loaded = ref(false)
const scrollElement = ref(null)
const scrollTop = ref(0)
const userAuthorised = ref(false)

const admin = computed(() => stores.admin.admin)
const authenticated = computed(() => stores.user.authenticated)
const loading = computed(() => stores.loading.app)
const loadingBarVisible = computed(() => Object.values(stores.loading.$state).includes(true))

const authenticatedAndReady = computed(() => {
  return authenticated.value && !loading.value && !route.value.meta.guest
})

const authorisedAndReady = computed(() => {
  return loaded.value && router.currentRoute.value.meta.permissions && can(router.currentRoute.value.meta.permissions)
})

const key = computed(() => {
  const path = route.value.path
  // for (const key of ['/settings', '/statistics', '/projects', '/annotationprojects', '/data', '/models']) {
  for (const key of ['/projects', '/annotationprojects']) {
    if (path.startsWith(key)) {
      const splitPath = splitString(path, '/')
      if (splitPath.splits >= 3) {
        return `${splitPath[1]}.${splitPath[2]}`
      }
      return key
    }
  }
  for (const key of ['/settings']) {
    if (path.startsWith(key)) return key
  }
  return path
})

watch(
  () => authorisedAndReady.value,
  (authorised: boolean | undefined) => {
    if (loaded.value && Object.keys(router.currentRoute.value.meta).length) {
      if (authorised) {
        userAuthorised.value = true
      } else {
        if ($user.role === 'annotator') {
          router.push({ path: '/annotationprojects' })
        } else {
          stores.initialState.objectless === true ? router.push({ path: '/getting-started' }) : router.push({ path: '/' })
        }
        stores.initialState.objectless = false
      }
    }
  }
)

onBeforeMount(async () => {
  await stores.initialState.load()
  loaded.value = true
})
</script>

<style lang="scss">
@import '@/scss/app.scss';

.v-header {
  margin-right: 60px;
  margin-left: 120px;
  overflow-x: auto;
  overflow-y: hidden;
}

.v-main {
  max-height: calc(100vh - 60px);
  padding-left: 5%;
  padding-top: 5%;
  padding-right: 5%;
  overflow-y: scroll;
  overflow-y: overlay; // Only some browsers support this
}

.app-loader {
  position: fixed;
  z-index: 999999999999;
  top: 0;
}

@media (max-width: $xs) {
  .v-container.is-main {
    overflow-x: auto;
  }

  .v-main {
    padding-top: 20px;
    padding-left: 20px;
    padding-right: 20px;
  }

  .v-header {
    margin-right: 20px;
    margin-left: 20px;
  }
}
</style>

<template>
  <Transition name="v-fade">
    <VButton v-if="top > 500" class="scroll-to-top" icon="ChevronUp" rounded type="secondary" @click="scroll" />
  </Transition>
</template>

<script setup lang="ts">
interface Props {
  top: number
  element: HTMLElement
}

const props = withDefaults(defineProps<Props>(), {
  element: window,
})

function scroll() {
  props.element.scrollTo(0, 0)
}
</script>

<style lang="scss" scoped>
.scroll-to-top {
  position: fixed;
  right: 20px;
  bottom: 20px;
}
</style>

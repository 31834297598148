import { StoreDefinition } from 'pinia'

interface Avatar {
  firenze_user_id: number
  version: number
}

type Avatars = Record<number, Avatar>

export const useAvatars = defineStore('avatars', () => {
  const avatars = ref<Avatars>({})
  const loading = ref(false)

  async function fetch() {
    if (Object.keys(avatars.value).length > 0 || loading.value) {
      return
    }

    await refresh()
  }

  async function refresh() {
    loading.value = true
    const { data } = await api.get<{ data: Avatar[] }>('avatars')
    avatars.value = {}
    for (const avatar of data.data) {
      avatars.value[avatar.firenze_user_id] = avatar
    }
    loading.value = false

    return data
  }

  return { avatars, fetch, refresh }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useAvatars as unknown as StoreDefinition, import.meta.hot))
}

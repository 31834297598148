import axios, { AxiosError } from 'axios'
import download from 'downloadjs'

import { sentry } from './services/sentry'
import { socket } from './services/socket'

export const baseURL = (import.meta.env.VITE_APP_URL as string) ?? window.location.origin
axios.defaults.xsrfCookieName = 'csrftoken'
axios.defaults.xsrfHeaderName = 'X-CSRFTOKEN'
axios.defaults.baseURL = baseURL
window.axios = axios
window.api = axios.create({ baseURL: `${baseURL}/api`, withCredentials: true })

export const errorHandler = (error: AxiosError<{ error_message?: string } | undefined>) => {
  if (error?.response?.data && typeof error.response.data === 'object') {
    if ('error_message' in error.response.data) {
      $message.error({ message: error.response.data.error_message, duration: 5500 })
    }
  }
  // throw error
  return Promise.reject(error)
}

// Register an error handler for failed api requests.
window.api.interceptors.response.use(
  (response) => response,
  (error) => errorHandler(error)
)

window.initialStatePromise = api.get('initial-state')

window.nextFrame = () => new Promise((resolve) => requestAnimationFrame(resolve))
window.download = download
window.socket = socket
window.sentry = sentry
window.copyObject = <T>(obj: T): T => JSON.parse(JSON.stringify(obj))
window.cloneObject = <T>(obj: T): T => structuredClone(toRaw(obj))
// window.copyObject = <T>(obj: T): T => structuredClone(toRaw(obj))
window.toRaw = toRaw
window.unref = unref
// eslint-disable-next-line no-console
console.table(import.meta.env)

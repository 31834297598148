import debounce from 'lodash-es/debounce'
import isEqual from 'lodash-es/isEqual'
import sortBy from 'lodash-es/sortBy'

import { baseURL } from '@/bootstrap'
import router from '@/router'
import { UsersStoreUser } from '@/stores/users'
import { Optional, Permission } from '@/types'

import { Meta } from '..'

export function openInSameTab(url: string, external = false) {
  const a = document.createElement('a')
  a.href = `${external ? '' : baseURL}${url}`
  a.target = '_blank'
  a.rel = 'noopener'
  a.click()
  a.remove()
}

export async function openInNewTab(url: string) {
  // When a download_url is provided, create a form that is submitted.
  // This form doesn't need csrf protection because technically it is an external url.
  if (['production', 'testing'].includes(env.app_env)) {
    const form = document.createElement('form')
    form.target = '_blank'
    form.method = 'POST'
    form.action = env.download_url + url
    form.rel = 'noopener'

    const input = document.createElement('input')
    input.name = '_token'
    const { data: token } = await api.get<string>('token')
    input.value = token
    input.type = 'hidden'
    form.appendChild(input)

    document.body.appendChild(form)
    form.submit()
    form.remove()
  } else {
    // without a download_url, we can perform a get on the same resource, which also skips csrf protection.
    openInSameTab(url)
  }
}

/**
 * Creates a new object by updating values in a target object. Only keys present in the target object are updated, no new entries are added.
 * The original target object is not updated.
 *
 * @example <caption>Updating an object.</caption>
 * let foo = {a: 1, b: 2}
 * const updates = {b: 3, c: 4}
 * foo = updateFromPartial(foo, updates)
 * // foo is now {a: 1, b: 3}
 * @param target Target object to update.
 * @param updates Entries to update.
 * @returns The updated object.
 */
export function updateFromPartial<T extends Record<string, any>>(target: T, updates: Partial<T>): T {
  const updatesInObj: Partial<typeof updates> = {}

  for (const key in updates) {
    if (key in target) {
      updatesInObj[key] = updates[key]
    }
  }

  return { ...target, ...updatesInObj }
}

/**
 * Based on an object's `id`, adds it to the array if it is not in there yet, removes it otherwise.
 * @param array Array of objects.
 * @param item object to toggle.
 */
export const arrayOfObjectsToggle = (array: { id: number }[], item: { id: number }) => {
  const index = array.findIndex((obj) => obj.id === item.id)
  if (index >= 0) {
    array.splice(index, 1)
  } else {
    array.push(item)
  }
}

export function can(permissions: Permission | Permission[]) {
  if (Array.isArray(permissions)) {
    return $user.hasPermissions(permissions)
  }
  return $user.hasPermission(permissions)
}

/**
 * Capitalises the first letter.
 */
export const capitalise = (text: string) => {
  return text.charAt(0).toUpperCase() + text.slice(1)
}

/**
 * Capitalises the first letter of each word.
 */
export const title = (text: string) => {
  return text
    .toLowerCase()
    .split(' ')
    .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
    .join(' ')
}

type RenameTypes<TCategory> = {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  renames: keyof (typeof stores.jsons)['renames'][TCategory]
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  fields: RenameFields<TCategory>
}

type RenameFields<TCategory extends keyof (typeof stores.jsons)['fields']> = (typeof stores.jsons)['fields'][TCategory][number]['abbr']

/**
 * Fetches a rename from a specific category by key.
 */
export const rename = <TJson extends 'renames' | 'fields', TCategory extends keyof (typeof stores.jsons)[TJson], TKey extends RenameTypes<TCategory>[TJson]>(json: TJson, category: TCategory, key: TKey): string => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  if (json === 'renames') return stores.jsons.renames[category as keyof typeof stores.jsons.renames][key] ?? key
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  if (json === 'fields') return stores.jsons.fields[category as keyof typeof stores.jsons.fields].find((obj) => obj.abbr === key)?.label ?? key
  return key as string
}

/**
 * Removes the trailing s, if it exists.
 */
export const singularise = (text: string) => {
  return text.replace(/s$/, '')
}

/**
 * Returns `true` if there is any items of the second array are present in the first.
 */
export const findOne = <T>(arr1: T[], arr2: T[]) => {
  return arr2.some(function (v) {
    return arr1.indexOf(v) >= 0
  })
}

/**
 * Returns `true` if all elements of the second array are present in the first.
 */
export const findAll = <T>(arr1: T[], arr2: T[]) => {
  return arr2.every(function (v) {
    return arr1.indexOf(v) >= 0
  })
}

/**
 * Formats a slug.
 * @example <caption>Format a slug.</caption>
 * // Returns 'Bacon and eggs'.
 * formatSlug('bacon_and_eggs')
 */
export const formatSlug = (text: string) => {
  return replaceAll(capitalise(text.toLowerCase()), '_', ' ')
}

/**
 * Replace all occurrences of a Regex match with a replacement.
 * @example <caption>Make menu item vegetarian.</caption>
 * // Returns 'egg, tofu, tofu and tofu'.
 * replaceAll('egg, bacon, sausage and Spam', '\b\w+\b(?<!egg)', 'tofu')
 */
export const replaceAll = (target: string, search: string, replacement: string) => {
  if (!target) return target
  return target.replace(new RegExp(search, 'g'), replacement)
}

/**
 * Shortens provided text to provided length. Postfixes with "..." if the text had to be shortened.
 * @param text Text to shorten.
 * @param n Maximum length of output text.
 */
export const shortenString = (text: string, n: number) => {
  if (text.length > n) {
    const shortName = `${text.substring(0, n - 3)}...`
    return shortName
  }
  return text
}

/**
 * Sets a specific number of decimals.
 *
 * @example
 * // Returns 42.420
 * setDecimals(42.42, 3)
 * // Returns 42.421
 * setDecimals(42.420999, 3)
 *
 * @param number Number to set.
 * @param n Number of decimals.
 */
export const setDecimals = (number: number, n: number) => {
  if (typeof number !== 'number') {
    number = parseFloat(number)
  }
  const splits = splitString(number.toString(), '0.0')
  if (splits.hits !== 0) {
    n++
    for (const c of splits.afterFirst) {
      if (c === '0') {
        n++
      } else {
        break
      }
    }
  }
  return number.toFixed(n)
}

export interface Splits {
  splits: number
  hits: number
  first: string
  last: string
  afterFirst: string
  beforeLast: string
  [index: number]: string
}

/**
 * Splits a string on given pattern matches and provides information and substrings.
 * @param str Text to split.
 * @param pattern Pattern to split on.
 */
export const splitString = (str: string, pattern: string): Splits => {
  const arr = str.split(pattern)
  const splits: Splits = {
    splits: arr.length,
    hits: arr.length - 1,
    first: arr[0] ?? '',
    last: arr[arr.length - 1] ?? '',
    afterFirst: str.substring(str.indexOf(pattern) + pattern.length, str.length),
    beforeLast: str.substring(0, str.lastIndexOf(pattern)),
  }
  arr.forEach((s: string, i: number) => {
    splits[i] = s
  })
  return splits
}

export type Assignee = { type: 'user'; value: UsersStoreUser }

/**
 * Finds the assignee of a database object.
 */
export const assignee = (book: { firenze_user: { id: number }; firenze_user_id?: number } | { firenze_user?: { id: number }; firenze_user_id: number }): Optional<Assignee> => {
  const userId = book.firenze_user_id ?? book.firenze_user?.id
  if (!userId) return // Type guard.
  const user: Optional<UsersStoreUser> = stores.users.users.find((user) => user.id === userId)
  return user ? { type: 'user', value: user } : undefined
}

/**
 * Checks if the content of two arrays is equal, regardless of the order.
 * @param arr1 First array.
 * @param arr2 Second array.
 */
export const isEqualRegardlessOfOrder = <T>(arr1: T[], arr2: T[]): boolean => {
  return isEqual(sortBy(arr1), sortBy(arr2))
}

export const debouncePushRoute = debounce(
  function (data) {
    router.push(data)
  },
  10,
  { leading: true, trailing: false }
)

export const ownerName = (ownerId: number) => {
  const user = Object.values(stores.users.users).find((user) => {
    return user.id === ownerId
  })
  return user?.full_name ?? ''
}

export function createMeta(): Meta {
  return {
    page: 1,
    num_pages: 1,
    count: 0,
    page_size: 10,
    paginate: true,
  }
}

export function humanSize(size: number) {
  let i = 0
  const units = ['', 'K', 'M', 'G', 'T', 'Q']

  while (size > 1000) {
    size = size / 1000
    i++
  }

  let humanSize = ''

  if (i > 1) {
    humanSize = Math.max(size, 0.1).toFixed(1)
  } else {
    humanSize = `${Math.round(size)}`
  }

  return `${humanSize} ${units[i]}`
}
